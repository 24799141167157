import { api, headers } from './base';

const loginRequest = async (formData) => {
    return await api.post('/login', formData);
}

const accountRequest = async (formData) => {
    return await api.post('/account', formData);
}

const forgotPasswordRequest = async (formData) => {
    return await api.post('/forgot_password', formData);
}

const resetPasswordRequest = async (formData) => {
    return await api.post('/reset_password', formData);
}

const accountUpdateRequest = async (formData) => {
    return await api.put('/account', formData, headers({'Content-Type': 'multipart/form-data'}));
}

export { loginRequest, accountRequest, forgotPasswordRequest, resetPasswordRequest, accountUpdateRequest };