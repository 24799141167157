import { api, headers } from './base';

const integrationEventsIndexRequest = async (accountId, filter, page = 1, perPage = 10) => {
    const queryStr = filter ? `&${Object.entries(filter).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')}` : '';
    return await api.get(`/integration_events/${accountId}?page=${page}&per_page=${perPage}${queryStr}`, headers());
}

const integrationEventsShowRequest = async (accountId, chatId, status = null, date, page = 1, perPage = 10) => {
    return await api.get(`/integration_events/${accountId}/by_chat_id/${chatId}?page=${page}&per_page=${perPage}&status=${status}&date=${date}`, headers());
}

const integrationEventDatesShowRequest = async (accountId, chatId, status = null, page = 1, perPage = 10) => {
    return await api.get(`/integration_events/${accountId}/by_chat_id/${chatId}/dates?page=${page}&per_page=${perPage}&status=${status}`, headers());
}

const integrationEventsCompleteRequest = async (accountId, eventId, attributes) => {
    return await api.post(`/integration_events/${eventId}/complete`, attributes, headers());
}

const integrationEventsToPendingModerationsByChatIdsRequest = async (accountId, chatIds) => {
    return await api.get(`/integration_events/${accountId}/to_moderate/by_chat_ids?chat_ids=${chatIds}`, headers());
}

const integrationEventsToModerationShowRequest = async (accountId, integrationEventId, page = 1, perPage = 10) => {
    return await api.get(`/integration_events/${accountId}/to_moderate/${integrationEventId}?page=${page}&per_page=${perPage}`, headers());
}

export {
    integrationEventsIndexRequest,
    integrationEventsShowRequest,
    integrationEventsCompleteRequest,
    integrationEventsToModerationShowRequest,
    integrationEventsToPendingModerationsByChatIdsRequest,
    integrationEventDatesShowRequest
};