import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Flex, Heading, Table, Switch, HoverCard, Text, Link as LinkUI, Box } from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';
import { accountsDomainPrivatesRequest, accountsDomainUpdateRequest } from '../../api/accounts_domains';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';
import { Pagination } from '../../components/pagination';
import { ExclamationTriangleIcon, GlobeIcon, LockClosedIcon, LockOpen2Icon, Pencil1Icon, PersonIcon } from '@radix-ui/react-icons';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function DomainsIndex() {
    const { user } = useAuth();
    const [domains, setDomains] = useState([]);

    const perPage = 10;
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(true);

    const updateDomains = useCallback((e, accountDomainId, domainId) => {
        const state = e.target.getAttribute('data-state') === 'checked' ?  false : true;
        const domainToUpdate = domains.find((domain) => domain.id === domainId);
        domainToUpdate.private = state;
        setDomains([...domains]);

        accountsDomainUpdateRequest(accountDomainId, { domain_id: domainId, private: state }).catch((error) => {
            console.error(error);
            e.target.setAttribute('data-state', state ? 'checked' : 'unchecked');
        })
    }, [domains]);

    const requestPerPage = useCallback((e, page) => {
        e.preventDefault();
        setLoading(true);

        accountsDomainPrivatesRequest(user.id, page, perPage).then((response) => {
            const domainsAttrs = response.data.data.map(({ domain, id, account }) => ({
                'account_id': account.id,
                'account_domain_id': id,
                'id': domain.id,
                'name': domain.name,
                'private': domain.private,
                'profiles_count': domain.profiles_count,
                'head_limit': domain.head_limit,
                'threshold_similarity': domain.threshold_similarity
            }));
            setDomains(domainsAttrs);

            setMeta(response.data.meta);

            setLoading(false);
        });
    }, [user])

    useEffect(() => {
        async function fetchDomains() {
            requestPerPage({ preventDefault: () => {} }, 1);
        }

        fetchDomains();
    }, [requestPerPage]);

    const ownDomain = (domain) => {
        return domain.account_id === user.id;
    }


    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='6'>
                <Heading >Meus domínios</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/dominios/novo'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Criar novo domínio
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <PlusIcon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            {loading && <Loading />}
            {!loading && domains.length === 0 && <EmptyList />}
            {!loading && domains.length > 0 && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Privado?</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Próprio?</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Qtd. perfis</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Qtd. mémoria/resposta</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Threshold</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {domains.map((domain, index) => (
                                <Table.Row key={index}>
                                    <Table.RowHeaderCell>{domain.name}</Table.RowHeaderCell>
                                    <Table.Cell>
                                        <Flex gap='2' align='center'>
                                            {ownDomain(domain) ? (
                                                <>
                                                    <Switch defaultChecked={domain.private} onClick={(e) => {updateDomains(e, domain.account_domain_id, domain.id)}} />
                                                    {domain.private ? (
                                                        <HoverCard.Root>
                                                            <HoverCard.Trigger>
                                                                <LockClosedIcon />
                                                            </HoverCard.Trigger>
                                                            <HoverCard.Content>
                                                                <Flex gap='5' direction='column' align='center'>
                                                                    <Text size='1'>
                                                                        Este domínio é privado.<br />
                                                                        Ao ser privado, somente você pode visualizar os perfis cadastrados.<br />
                                                                        Clique no cadeado para torná-lo público.<br />
                                                                        Ao ser público, todos os usuários podem visualizar os perfis cadastrados.<br />
                                                                        E com isso, você pode remunerar quem utilizar seus perfis.
                                                                    </Text>
                                                                </Flex>
                                                            </HoverCard.Content>
                                                        </HoverCard.Root>
                                                    ) : <LockOpen2Icon />}
                                                </>) : ''
                                            }

                                        </Flex>
                                    </Table.Cell>
                                    <Table.Cell>{ownDomain(domain) ? <PersonIcon /> : <GlobeIcon />}</Table.Cell>
                                    <Table.Cell>
                                        {domain.profiles_count > 0 && domain.profiles_count}
                                        {domain.profiles_count === 0 && (
                                            <HoverCard.Root>
                                                <HoverCard.Trigger>
                                                    <ExclamationTriangleIcon style={{color: 'var(--accent-10)'}} />
                                                </HoverCard.Trigger>
                                                <HoverCard.Content>
                                                    <Flex gap='2' align='center'>
                                                        <Text size='1'>Nenhum perfil cadastrado</Text>
                                                        <Button size='1' asChild>
                                                            <Link to={`/perfis/novo?domainName=${domain.name}`}>Cadastrar perfil</Link>
                                                        </Button>
                                                    </Flex>
                                                </HoverCard.Content>
                                            </HoverCard.Root>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>{domain.head_limit}</Table.Cell>
                                    <Table.Cell>{domain.threshold_similarity}</Table.Cell>
                                    <Table.Cell>
                                        {ownDomain(domain) ? (
                                            <Button size='1' asChild>
                                                <LinkUI asChild>
                                                    <Link to={`/dominios/${domain.name}/editar`}>
                                                        <Pencil1Icon />
                                                    </Link>
                                                </LinkUI>
                                            </Button>
                                        ) : ''}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Table.Root>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Privado?</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {domains.map((domain, index) => (
                                <Table.Row key={index}>
                                    <Table.RowHeaderCell>{domain.name}</Table.RowHeaderCell>
                                    <Table.Cell>
                                        <Flex gap='2' align='center'>
                                            {ownDomain(domain) ? (
                                                <>
                                                    <Switch defaultChecked={domain.private} onClick={(e) => {updateDomains(e, domain.account_domain_id, domain.id)}} />
                                                    {domain.private ? (
                                                        <HoverCard.Root>
                                                            <HoverCard.Trigger>
                                                                <LockClosedIcon />
                                                            </HoverCard.Trigger>
                                                            <HoverCard.Content>
                                                                <Flex gap='5' direction='column' align='center'>
                                                                    <Text size='1'>
                                                                        Este domínio é privado.<br />
                                                                        Ao ser privado, somente você pode visualizar os perfis cadastrados.<br />
                                                                        Clique no cadeado para torná-lo público.<br />
                                                                        Ao ser público, todos os usuários podem visualizar os perfis cadastrados.<br />
                                                                        E com isso, você pode remunerar quem utilizar seus perfis.
                                                                    </Text>
                                                                </Flex>
                                                            </HoverCard.Content>
                                                        </HoverCard.Root>
                                                    ) : <LockOpen2Icon />}
                                                </>) : ''
                                            }

                                        </Flex>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {ownDomain(domain) ? (
                                            <Button size='1' asChild>
                                                <LinkUI asChild>
                                                    <Link to={`/dominios/${domain.name}/editar`}>
                                                        <Pencil1Icon />
                                                    </Link>
                                                </LinkUI>
                                            </Button>
                                        ) : ''}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>
            </>}

            <Pagination meta={meta} requestPerPage={requestPerPage} />
        </Container>
    );
}

export { DomainsIndex };