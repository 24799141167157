import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextareaForm } from '../../../components/form_types/textarea_form';
import { TextIcon } from '@radix-ui/react-icons'

function TextForm(props) {
    const { textOfState } = props;
    const navigate = useNavigate();
    const [text, setText] = useState(textOfState);

    useEffect(() => {
        if (textOfState) {
            setText(textOfState);
        }
    }, [textOfState]);

    const changeText = (e) => {
        setText(e.target.value);
    }

    const continueToNextStep = (e) => {
        e.preventDefault();
        navigate('/memorias/nova/categorizar', { state: { files: [], text: text, links: [] } });
    }

    return (
        <TextareaForm
            name='conteúdo da mémoria'
            type='text'
            text={text}
            icon={<TextIcon width={30} height={30} />}
            changeText={changeText}
            continueToNextStep={continueToNextStep}
            continueButtonName='avançar'/>
    );
}

export { TextForm };