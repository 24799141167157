import { Table, Spinner, AlertDialog, Button, Flex, Box } from '@radix-ui/themes';
import { useLocation } from 'react-router-dom';
import { CheckIcon, Cross2Icon, TrashIcon } from '@radix-ui/react-icons';
import { useEffect, useState, useCallback } from 'react';
import { linkExtractionAccountRequest, linkExtractionDeleteRequest } from '../../../api/link_extractions';
import { useAuth } from '../../../hooks/auth';
import { Loading } from '../../../components/loading';
import { EmptyList } from '../../../components/empty_list';
import { Pagination } from '../../../components/pagination';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../../contexts/responsive_tools';

function LinkExtractionsTable() {
    const { user } = useAuth();
    const perPage = 10;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    const [linkExtractions, setLinkExtractions] = useState([]);
    const [linkExtractionsLoading, setLinkExtractionsLoading] = useState(true);
    const [linkExtractionsMeta, setLinkExtractionsMeta] = useState({});
    const [linkExtractionsCurrentPage, setLinkExtractionsCurrentPage] = useState(1);

    const deleteLinkExtraction = (e, id) => {
        e.preventDefault();
        linkExtractionDeleteRequest(id).then((_) => {
            fetchLinkExtractions({ preventDefault: () => { } }, linkExtractionsCurrentPage);
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchLinkExtractions = useCallback((e, page) => {
        e.preventDefault();
        setLinkExtractionsLoading(true);

        linkExtractionAccountRequest(user.id, page, perPage).then((response) => {
            setLinkExtractions(response.data.data);

            setLinkExtractionsMeta(response.data.meta);
            setLinkExtractionsCurrentPage(response.data.meta.page);

            setLinkExtractionsLoading(false);
        }).catch((error) => {
            console.log(error);
            setLinkExtractionsLoading(false);
        });
    }, [user]);

    const handleStatus = (status) => {
        switch (status) {
            case 'processed':
                return <CheckIcon width={30} height={30} color='green' />;
            case 'error':
                return <Cross2Icon width={30} height={30} color='red' />;
            default:
                return <Spinner />;
        }
    }

    const handleMessage = (status, message) => {
        if (status === 'error') {
            return message;
        } else if (status === 'processed') {
            return 'ok';
        } else if (status === 'processing') {
            return 'Processando...';
        } else if (status === 'pending') {
            return 'Pendente de processamento';
        } else {
            return '';
        }
    }

    useEffect(() => {
        fetchLinkExtractions({ preventDefault: () => { } }, page ? page : 1);
    }, [fetchLinkExtractions, page]);

    return (
        <>
            {linkExtractionsLoading && <Loading />}
            {!linkExtractionsLoading && linkExtractions.length === 0 && <EmptyList />}
            {!linkExtractionsLoading && linkExtractions.length > 0 && (
                <>
                    <Box display={displayOnlyLaptopsAndDesktops()}>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Título do arquivo</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Mensagem</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Criado em</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {linkExtractions.map((linkExtraction) => (
                                    <Table.Row key={linkExtraction.id}>
                                        <Table.RowHeaderCell>{linkExtraction.title.substring(0, 50) + (linkExtraction.title.length > 50 ? '...' : '')}</Table.RowHeaderCell>
                                        <Table.Cell>{handleStatus(linkExtraction.status)}</Table.Cell>
                                        <Table.Cell>{handleMessage(linkExtraction.status, linkExtraction.message)}</Table.Cell>
                                        <Table.Cell>{new Date(linkExtraction.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                        <Table.Cell>
                                            <AlertDialog.Root>
                                                    <AlertDialog.Trigger>
                                                        <Button size="1" mr='2'>
                                                            <TrashIcon width={20} height={20} />
                                                        </Button>
                                                    </AlertDialog.Trigger>
                                                    <AlertDialog.Content maxWidth="450px">
                                                        <AlertDialog.Title>Deletar</AlertDialog.Title>
                                                        <AlertDialog.Description size="2">
                                                            Você tem certeza que deseja deletar esta memória?
                                                        </AlertDialog.Description>

                                                        <Flex gap="3" mt="4" justify="end">
                                                            <AlertDialog.Cancel>
                                                                <Button variant="soft" color="gray">
                                                                    Cancel
                                                                </Button>
                                                            </AlertDialog.Cancel>
                                                            <AlertDialog.Action>
                                                                <Button variant="solid" color="red" onClick={(e) => deleteLinkExtraction(e, linkExtraction.id)}>
                                                                    Deletar
                                                                </Button>
                                                            </AlertDialog.Action>
                                                        </Flex>
                                                    </AlertDialog.Content>
                                                </AlertDialog.Root>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </Box>

                    <Box display={displayOnlyMobiles()}>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Título do arquivo</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {linkExtractions.map((linkExtraction) => (
                                    <Table.Row key={linkExtraction.id}>
                                        <Table.RowHeaderCell>{linkExtraction.title.substring(0, 20) + (linkExtraction.title.length > 20 ? '...' : '')}</Table.RowHeaderCell>
                                        <Table.Cell>
                                            <AlertDialog.Root>
                                                    <AlertDialog.Trigger>
                                                        <Button size="1" mr='2'>
                                                            <TrashIcon width={20} height={20} />
                                                        </Button>
                                                    </AlertDialog.Trigger>
                                                    <AlertDialog.Content maxWidth="450px">
                                                        <AlertDialog.Title>Deletar</AlertDialog.Title>
                                                        <AlertDialog.Description size="2">
                                                            Você tem certeza que deseja deletar esta memória?
                                                        </AlertDialog.Description>

                                                        <Flex gap="3" mt="4" justify="end">
                                                            <AlertDialog.Cancel>
                                                                <Button variant="soft" color="gray">
                                                                    Cancel
                                                                </Button>
                                                            </AlertDialog.Cancel>
                                                            <AlertDialog.Action>
                                                                <Button variant="solid" color="red" onClick={(e) => deleteLinkExtraction(e, linkExtraction.id)}>
                                                                    Deletar
                                                                </Button>
                                                            </AlertDialog.Action>
                                                        </Flex>
                                                    </AlertDialog.Content>
                                                </AlertDialog.Root>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </Box>
                </>
            )}

            <Pagination meta={linkExtractionsMeta} requestPerPage={fetchLinkExtractions} />
        </>
    );
}

export { LinkExtractionsTable };