import React, { useState, useEffect, useCallback } from 'react';
import { Table, IconButton, Container, Flex, Button, Heading, Box, DropdownMenu } from '@radix-ui/themes';
import { GlobeIcon, MagnifyingGlassIcon, Pencil1Icon, PersonIcon, PlusIcon } from '@radix-ui/react-icons';
import { profileShowByCreatorNameRequest } from '../../api/profiles';
import { useAuth } from '../../hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';
import { Pagination } from '../../components/pagination';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function ProfileIndex() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [profiles, setProfiles] = useState([]);

    const perPage = 10;
    const [meta, setMeta] = useState({});

    const goToShow = (e, id) => {
        e.preventDefault();
        navigate(`/perfis/${id}`);
    }

    const goToEdit = (e, id) => {
        e.preventDefault();
        navigate(`/perfis/${id}/editar`);
    }

    const fetchProfiles = useCallback((e, page) => {
        e.preventDefault();
        setLoading(true);

        profileShowByCreatorNameRequest(user.name, page, perPage).then((response) => {
            setProfiles(response.data.data);

            setMeta(response.data.meta);

            setLoading(false);
        });
    }, [user]);

    useEffect(() => {
        fetchProfiles({ preventDefault: () => { } }, 1);
    }, [fetchProfiles]);

    const ownProfile = (profile) => {
        return profile.creator.identifier === user.name;
    }

    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='4'>
                <Heading >Meus perfis</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/perfis/novo'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Inserir novo perfil
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <PlusIcon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            {loading && <Loading />}
            {!loading && profiles.length === 0 && <EmptyList />}
            {!loading && profiles.length > 0 && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <Table.Root size='3'>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Título</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Próprio</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {profiles.map((profile, index) => (
                                <Table.Row key={index}>
                                    <Table.RowHeaderCell>{profile.title}</Table.RowHeaderCell>
                                    <Table.Cell>{(ownProfile(profile)) ? <PersonIcon /> : <GlobeIcon />}</Table.Cell>
                                    <Table.Cell>
                                        {(ownProfile(profile)) ?
                                            <>
                                                <IconButton size="1" mr='2' onClick={(e) => { goToEdit(e, profile.id) }} >
                                                    <Pencil1Icon width={20} height={20} />
                                                </IconButton>

                                                <IconButton size="1" mr='2' onClick={(e) => { goToShow(e, profile.id) }} >
                                                    <MagnifyingGlassIcon width={20} height={20} />
                                                </IconButton>
                                            </>
                                        : ''}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Table.Root size='3'>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Título</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {profiles.map((profile, index) => (
                                <Table.Row key={index}>
                                    <Table.RowHeaderCell>{profile.title}</Table.RowHeaderCell>
                                    <Table.Cell>
                                        {(ownProfile(profile)) ?
                                            <>
                                                <DropdownMenu.Root>
                                                    <DropdownMenu.Trigger>
                                                        <Button variant='soft'>
                                                            ...
                                                            <DropdownMenu.TriggerIcon />
                                                        </Button>
                                                    </DropdownMenu.Trigger>

                                                    <DropdownMenu.Content>
                                                        <DropdownMenu.Item onSelect={(e) => { goToEdit(e, profile.id) }}>
                                                            <Pencil1Icon width={20} height={20} />
                                                            Editar
                                                        </DropdownMenu.Item>

                                                        <DropdownMenu.Item onSelect={(e) => { goToShow(e, profile.id) }}>
                                                            <MagnifyingGlassIcon width={20} height={20} />
                                                            Visualizar
                                                        </DropdownMenu.Item>
                                                    </DropdownMenu.Content>
                                                </DropdownMenu.Root>
                                            </>
                                        : ''}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>
            </>}

            <Pagination meta={meta} requestPerPage={fetchProfiles} />
        </Container>
    )
}

export { ProfileIndex }