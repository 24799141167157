import { Separator, Inset, Text, Button, Flex, Strong, Badge } from '@radix-ui/themes';
import { TrashIcon, UploadIcon } from '@radix-ui/react-icons';
import { useState, useCallback, useRef } from 'react';

function DragDropFilesForm (props) {
    const { name, accept, icon, action, limit = 10, autoSubmit = false, hasError, errorMessage } = props;
    const [dragOver, setDragOver] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [files, setFiles] = useState([]);
    const [filesSelected, setFilesSelected] = useState(false);

    const fileInputRef = useRef();

    // Função para lidar com arquivos soltos
    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0 && event.dataTransfer.files.length <= limit) {
            const files = Array.from(event.dataTransfer.files);
            const filteredFiles = files.filter(file => {
                const fileExtension = file.name.split('.').pop();
                return accept.includes(fileExtension);
            });

            if (filteredFiles.length > 0) {
                handleFiles(filteredFiles);
                event.dataTransfer.clearData();
            } else {
                hasError(true);
                errorMessage(`Você pode selecionar arquivos com extensões: ${accept.split(',').join(', ')}`);
            }
        } else {
            hasError(true);
            errorMessage(`Você pode selecionar no máximo ${limit} arquivos.`);
        }

    }, [accept, limit, hasError, errorMessage]); // eslint-disable-line

    // Função para lidar com a seleção de arquivos através do input
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0 && event.target.files.length <= limit) {
            const files = event.target.files;
            handleFiles(files);
        } else {
            hasError(true);
            errorMessage(`Você pode selecionar no máximo ${limit} arquivos.`);
        }
    };

    // Função para processar os arquivos
    const handleFiles = (files) => {
        setFilesSelected(true);
        setFileNames(Object.keys(files).map((key) => files[key].name));
        setFiles(Array.from(files));

        if (autoSubmit) {
            action(files);
        }
    };

    // Função para limpar os arquivos selecionados
    const clearFiles = () => {
        setFileNames([]);
        setFilesSelected(false);
        setFiles([]);
    };

    // Funções para lidar com drag over e drag leave
    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
    }, []);

    const handlerClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current.click();
    }

    const continueToNextStep = (e) => {
        e.preventDefault();
        action(files);
    }

    return (
        <Inset
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            style={{
                border: files.length > 0 ? '2px dashed rgb(0 179 24 / 82%)' : '2px dashed rgba(183, 183, 183, 0.25)',
                backgroundColor: dragOver ? 'rgba(183, 183, 183, 0.2)' : 'transparent',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: 'var(--space-2)'
            }}
        >
            <input
                type="file"
                name={name}
                onChange={handleFileChange}
                accept={accept}
                multiple
                style={{ display: 'none' }}
                ref={fileInputRef}
            />

            <Flex gap='2' align='center' direction='column'>
                <Flex gap='2' align='center'>
                    {icon}
                    {filesSelected ? (
                        <Strong size="2">{fileNames.length > 1 ? 'Arquivos Selecionados:' : 'Arquivo Selecionado:'}</Strong>
                    ) : <Strong size="2">Arraste {name} aqui.</Strong>}
                </Flex>

                <Flex gap='2' align='center' direction='column' >
                    {fileNames.map((fileName, i) => (
                        <Text size="2" key={i}>{fileName}</Text>
                    ))}

                    {fileNames.length === 0 && (
                        <Badge size="2" variant="gray" asChild>
                            <Flex direction='column' gap='2' align='center' style={{minWidth: '250px'}} p='2' >
                                <Strong>Limite:</Strong>
                                <Text>
                                    {limit} arquivos
                                </Text>

                                <Separator size='4' />

                                <Strong>Tipos aceitos:</Strong>
                                {accept.split(',').map((type, i) => (
                                    <Text key={i}>{type}</Text>
                                ))}
                            </Flex>
                        </Badge>
                    )}
                </Flex>
            </Flex>

            {filesSelected ? (
                <Flex gap='2' align='center' mt='2'>
                    <Button
                        mt='6'
                        color='red'
                        onClick={clearFiles}
                    >
                        <TrashIcon width={20} height={20} />
                        Limpar
                    </Button>

                    <Button
                        mt='6'
                        type='submit'
                        onClick={continueToNextStep}
                    >
                        Upload
                        <UploadIcon width={20} height={20} />
                    </Button>
                </Flex>
            ) : (
                <Button
                    mt='2'
                    onClick={handlerClick}
                >
                    Escolher {name}
                </Button>
            )}
        </Inset>
    );
}

export { DragDropFilesForm };