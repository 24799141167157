import { api, headers } from './base';

const dataIntegrationsRequest = async (id, page, perPage=10) => {
    return await api.get(`/data_integrations/${id}?page=${page}&per_page=${perPage}`, headers());
}

const dataIntegrationShowRequest = async (id) => {
    return await api.get(`/data_integrations/detail/${id}`, headers());
}

export { dataIntegrationsRequest, dataIntegrationShowRequest };