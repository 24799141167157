import React from 'react';
import ReactDOM from 'react-dom/client';

import '@radix-ui/themes/styles.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Theme } from '@radix-ui/themes'
import { AuthProvider } from './hooks/auth';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const appearance = () => {
  return JSON.parse(localStorage.getItem('accountConfiguration'))?.appearance || 'light';
}

const contentHtml = () => {
  return (
    <AuthProvider>
      <Theme accentColor="violet" grayColor="sand" panelBackground="solid" appearance={appearance} radius="small" scaling="100%" style={{height: '100vh'}}>
        <App />
      </Theme>
    </AuthProvider>
  )
}

root.render(
  (process.env.REACT_APP_ENV === 'development' ? <React.StrictMode>{contentHtml()}</React.StrictMode> : contentHtml())
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
