import { useState } from 'react';
import { Container, Heading, Box, Flex, Text, Button } from '@radix-ui/themes';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { useNavigate, useLocation } from 'react-router-dom';

function CreatorInputsSucess() {
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useState(location.state ? location.state : {});

  const goToIndex = (e) => {
    e.preventDefault();
    navigate('/memorias', { state: state });
  }

  return (
    <Container size='3' mt='2' p='2'>
        <Heading>Sucesso!</Heading>

        <>
            <Box my='2'>
                <Flex direction='column' gap='2'>
                    <Text>Sua mémoria foi inserida com sucesso!</Text>
                </Flex>
            </Box>

            <Box my='4'>
                <Button onClick={goToIndex} >
                    Continuar
                    < ArrowRightIcon />
                </Button>
            </Box>
        </>
    </Container>

  );
}

export { CreatorInputsSucess };