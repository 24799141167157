import React, { useState } from 'react';
import { useMask } from '@react-input/mask';
import {
    Container,
    Heading,
    Box,
    Flex,
    Button,
    Link as LinkUI,
    Separator,
} from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { Link, useNavigate } from 'react-router-dom';
import { encryptWithPublicKey } from '../../support/cryptography';
import { accountRequest } from '../../api/accounts';

function RegisterNew() {
    const navigate = useNavigate();
    const cellphoneRef = useMask({ mask: '+__ (__) _____-____', replacement: { _: /\d/ } });
    const [isLoading, setIsLoading] = useState(false);
    const initialServerErrors = {
        name: false,
        email: false,
        cellphone: false,
        provider: false,
        password: false
    };

    const [serverErrors, setServerErrors] = React.useState(initialServerErrors);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        cellphone: '',
        provider: 'sandbox',
        password: ''
    });

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            iat: new Date().toISOString()
        });
    };

    var handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setServerErrors(initialServerErrors);

        setFormData({
            ...formData,
            iat: new Date().toISOString()
        });

        encryptWithPublicKey(formData).then((data) => {
            accountRequest({
                hash: data
            }).then((response) => {
                var body = JSON.parse(response.request.response);
                setIsLoading(false);
                setServerErrors(initialServerErrors);

                navigate('/cadastro/sucesso', { state: { body: body } });
            }).catch((error) => {
                setServerErrors(error.response.data);
                setIsLoading(false);
            });
        }).catch((error) => {
            console.error('Error encrypting data: ', error);
        });
    }


    return (
        <Container size='4' maxWidth='920px' p='2' align='center'>
            <Heading mt='4'>Faça seu Cadastro</Heading>

            <Flex direction='column' mt='2'>
                <Box>
                    <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                        <Form.Field className="FormField" name="name" serverInvalid={serverErrors.name} >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Nome</Form.Label>

                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira o nome
                                </Form.Message>

                                <Form.Message className='FormMessage' match="typeMismatch" forceMatch={serverErrors.name}>
                                    Nome inválido
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="text" name="name" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>

                        <Form.Field className="FormField" name="email" serverInvalid={serverErrors.email} >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Email</Form.Label>

                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira o email
                                </Form.Message>

                                <Form.Message className='FormMessage' match="typeMismatch" forceMatch={serverErrors.email}>
                                    Email em uso
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="email" name="email" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>

                        <Form.Field className="FormField" name="cellphone" serverInvalid={serverErrors.cellphone} >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Celular</Form.Label>

                                <Form.Message className='FormMessage' match={(cellphone, formData) => (cellphone.length !== 19)}>
                                    Celular inválido
                                </Form.Message>

                                <Form.Message className='FormMessage' match="typeMismatch" forceMatch={serverErrors.cellphone}>
                                    Celular inválido
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input ref={cellphoneRef} className="Input" type="text" name="cellphone" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>


                        <Form.Field className="FormField" name="password" serverInvalid={serverErrors.password} >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Senha</Form.Label>

                                <Form.Message className='FormMessage' match={(password, formData) => password.length < 8 || !password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)}>
                                    Deve conter letras, números e 8 caracteres
                                </Form.Message>

                                <Form.Message className='FormMessage' match="typeMismatch" forceMatch={serverErrors.password}>
                                    Deve conter letras, números e 8 caracteres
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="password" name="password" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>


                        <Form.Field className="FormField" name="confirm_password">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Confirme a Senha</Form.Label>

                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira a senha
                                </Form.Message>

                                <Form.Message className='FormMessage' match={(value, formData) => value !== formData.get('password')}>
                                    As senhas não conferem
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="password" name="confirm_password" required />
                            </Form.Control>
                        </Form.Field>

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={isLoading} >
                                Salvar
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Box>

                <Flex justify='start' mt='4'>
                    <Box pr='2'>
                        <LinkUI asChild>
                            <Link to='/login'>Login</Link>
                        </LinkUI>
                    </Box>
                    <Separator orientation='vertical' />
                    <Box pl='2'>
                        <LinkUI asChild>
                            <Link to='/recuperar_senha'>Recuperar senha</Link>
                        </LinkUI>
                    </Box>
                </Flex>
            </Flex>
        </Container>
    );
}

export { RegisterNew };