import React, { useState } from 'react';
import { Flex, Button, Strong, IconButton, HoverCard, Box, Heading, Text, Callout } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { PlusIcon, TrashIcon, ArrowRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { useEffect } from 'react';
import { accountsDomainAllRequest } from '../../../api/accounts_domains';
import { profilesCreateRequest } from '../../../api/profiles';

function ProfileStep(props) {
    const { completeStep, user, updateSession } = props;
    const [loading, setLoading] = useState(false);
    const [skillCounts, setSkillCounts] = useState([0]);
    const [skills, setSkills] = useState({});
    const [domain, setDomain] = useState({});
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        text: ''
    });

    useEffect(() => {
        const fetchDomain = async () => {
            accountsDomainAllRequest(user.id, 1).then((response) => {
                setDomain(response.data.data[0].domain);
            }).catch((error) => {
                console.log(error);
            });
        }

        fetchDomain();
    }, [user]);

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        var body = {
            ...formData,
            domain_name: domain.name,
            creator_identifier: user.name,
            skills: Object.values(skills)
        };

        profilesCreateRequest(body).then((response) => {
            user.has_profile = true;
            updateSession(user);

            setLoading(false);
            completeStep(2);
        }).catch((error) => {
            setErrors([error.response.data.error]);
            setLoading(false);
        });
    }

    const deleteTextInput = (e, index) => {
        e.preventDefault();

        if (skillCounts.length === 1) {
            return;
        }

        setSkillCounts(skillCounts.filter((i) => i !== index));

        const skillChanged = { ...skills };
        delete skillChanged[`skills[${index}]`];
        setSkills(skillChanged);
    }

    var handleSkillChange = (e) => {
        skills[e.target.name] = e.target.value;
        setSkillCounts([...skillCounts]);
        setSkills(skills);
    }

    const addTextInput = (e) => {
        e.preventDefault();
        var maxSkills = Math.max(...skillCounts);
        setSkillCounts([...skillCounts, ++maxSkills]);
    }

    const SkillInput = (index) => (
        <Form.Field className="FormField" key={index} name={`skills[${index}]`} >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Message className='FormMessage' match="valueMissing">
                    Descreva uma habilidade ou permissão
                </Form.Message>
            </Flex>

            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Control asChild>
                            <input className="Input" type='text' autoComplete='off' name={`skills[${index}]`} onChange={handleSkillChange} required style={{ width: '100%' }} />
                        </Form.Control>
                    </HoverCard.Trigger>

                    {skillsInstructions({left: '860px', top: '-50px'})}
                </HoverCard.Root>

                <IconButton size="1" ml='6' color='red' onClick={(e) => deleteTextInput(e, index)} >
                    <TrashIcon width={20} height={20} />
                </IconButton>
            </Flex>
        </Form.Field>
    )

    const textInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='green' />
                    <Box>
                        <Heading size="3" as="h3">
                            Estimulos criativos
                        </Heading>
                        <Text as="div" size="2">
                            - Quais os objetivos dessa IA?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de interação?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de respostas?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de comportamento?
                        </Text>

                        <Text as="div" size="2">
                            - Qual o tom de voz esperado?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de personalidade?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const skillsInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='green' />
                    <Box>
                        <Heading size="3" as="h3">
                            Habilidades e permissões
                        </Heading>

                        <Text as="div" size="2">
                            - Quais as permissões que essa IA deve ter?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as permissões que essa IA não deve ter?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    return (
        <Box mt='2'>
            {errors.length > 0 && (
                <Callout.Root mb='4' color="red">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        {errors.map((error, index) => (
                            <Text key={index}>{error}</Text>
                        ))}
                    </Callout.Text>
                </Callout.Root>
            )}

            <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                <Form.Field className="FormField" name="title" >
                    <Flex align="baseline" direction='column' style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Nome do perfil</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira um nome para seu perfil
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild>
                        <input className="Input" type="text" name="title" value={formData?.title} onChange={handleChange} required />
                    </Form.Control>
                </Form.Field>

                <Form.Field className="FormField" name="text">
                    <Flex align="baseline" direction='column' style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Instruções personalizadas</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira o conteúdo
                        </Form.Message>
                    </Flex>

                    <HoverCard.Root>
                        <HoverCard.Trigger>
                            <Form.Control asChild>
                                <textarea className="Textarea" type="text" name="text" value={formData?.text} onChange={handleChange} required />
                            </Form.Control>
                        </HoverCard.Trigger>

                        {textInstructions({left: '860px', top: '-330px'})}
                    </HoverCard.Root>
                </Form.Field>

                <Flex align="center" style={{ justifyContent: 'space-between' }} mb='2' mt='4'>
                    <Strong>Como a IA deveria se comportar?</Strong>

                    <IconButton size="1" onClick={addTextInput} >
                        <PlusIcon width={20} height={20} />
                    </IconButton>
                </Flex>

                {skillCounts?.map(SkillInput)}

                <Form.Submit asChild>
                    <Button size="2" type="submit" loading={loading} >
                        Avançar
                        <ArrowRightIcon width={20} height={20} />
                    </Button>
                </Form.Submit>
            </Form.Root>
        </Box>
    );
}

export { ProfileStep }