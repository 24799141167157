import { Container, Box, Progress, Text } from '@radix-ui/themes';

function Loading() {
    return (
        <Container size='3' mt='6'>
            <Box>
                <Progress />
                <Text>Carregando...</Text>
            </Box>
        </Container>
    );
}

export { Loading }