import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')));
    const [accountConfiguration, setAccountConfiguration] = useState(JSON.parse(localStorage.getItem('accountConfiguration')) || {});

    const login = (userData) => {
        userData['onboarding'] = userData.has_domain && userData.has_profile;
        localStorage.setItem('userData', JSON.stringify(userData));
        setUser(userData);
    };

    const updateSession = (userData) => {
        localStorage.setItem('userData', JSON.stringify(userData));
        setUser(userData);
    };

    const updateAccountConfiguration = (data) => {
        localStorage.setItem('accountConfiguration', JSON.stringify(data));
        setAccountConfiguration(data);
    }

    const logout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('accountConfiguration');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, accountConfiguration, login, updateSession, logout, updateAccountConfiguration }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);