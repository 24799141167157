import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const { user } = useAuth();
    const hasOnboarding = (user && user.onboarding) || location.pathname === '/welcome' || location.pathname === '/sair';

    return user ? (hasOnboarding ? children : <Navigate to='/welcome' />) : <Navigate to="/login" replace />;
};

export { PrivateRoute };