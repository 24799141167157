import React, { useState, useEffect, useCallback } from 'react';
import { Button, Code, Flex, Heading, TextArea, Text, Dialog, Avatar, AspectRatio, Separator, Link } from '@radix-ui/themes';
import { CheckIcon, Cross1Icon, PaperPlaneIcon, Pencil1Icon, ResetIcon, FileIcon } from '@radix-ui/react-icons';
import { MarkdownText } from '../../../components/markdown_text';
import { useAuth } from '../../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { integrationEventsCompleteRequest } from '../../../api/integration_events';

function Moderation(props) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { answers } = props;
    const [event] = useState(answers[0]);
    const [newText, setNewText] = useState(event?.integration_responses[0]?.content_view.responses[0]?.text);
    const [editMode, setEditMode] = useState(false);
    const [moderationLoading, setModerationLoading] = useState(false);
    const [groupedAnswers, setGroupedAnswers] = useState([]);

    const handleAnswer = useCallback(() => {
        const messages = []
        const answersSorted = answers.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        answersSorted.forEach((event) => {
            event.payload.messages.forEach((event_message) => {
                let message = {}
                if (event_message.type === 'action') {
                    if (event_message.action?.type === 'reaction') {
                        message = {
                            'id': event_message.id,
                            'text': event_message.action?.emoji,
                            'action': 'reaction',
                            'type': event_message.action?.type,
                            'created_at': event.created_at
                        }
                    } else {
                        const targetId = event_message.action?.target;
                        messages.forEach((message) => {
                            if (message.id === targetId) {
                                message.original_text = message.text;
                                message.text = event_message.action?.edited_content?.body;
                                message.action = event_message.action?.type;
                                message.created_at = event.created_at;
                            }
                        })
                    }
                } else if (event_message.type === 'text') {
                    message = {
                        'id': event_message.id,
                        'text': event_message.text?.body,
                        'type': event_message?.type,
                        'action': 'nothing',
                        'created_at': event.created_at
                    }
                } else if (event_message.type === 'voice') {
                    message = {
                        'id': event_message.id,
                        'text': event.extra_data?.voice_transcription,
                        'type': event_message?.type,
                        'action': 'nothing',
                        'created_at': event.created_at
                    }
                } else if (event_message.type === 'image') {
                    message = {
                        'id': event_message.id,
                        'link': event_message.image?.link,
                        'preview': event_message.image?.preview,
                        'type': event_message?.type,
                        'action': 'nothing',
                        'created_at': event.created_at
                    }
                } else if (event_message.type === 'document') {
                    message = {
                        'id': event_message.id,
                        'link': event_message.document?.link,
                        'type': event_message?.type,
                        'action': 'nothing',
                        'created_at': event.created_at
                    }
                }

                if (Object.keys(message).length !== 0) {
                    messages.push(message);
                }
            });
        });

        setGroupedAnswers(messages);
    }, [answers]);

    useEffect(() => {
        handleAnswer();
    }, [handleAnswer]);

    const handleEditMode = (value) => {
        setEditMode(false);

        if (!value) {
            setNewText(event?.integration_responses[0]?.content_view.responses[0]?.text);
        }
    }

    const moderationAttributes = (event) => {
        return {
            "content_view_id": event.integration_responses[0]?.content_view?.id,
            "text_correction": currentTextIsNotEqual(event) ? newText : null
        }
    }

    const fetchIntegrationEventComplete = (event, attributes) => {
        setModerationLoading(true);
        integrationEventsCompleteRequest(user.id, event.id, attributes).then(response => {
            navigate('/eventos-recebidos');
        }).catch(error => {
            console.error(error);
        });
    }

    const approveIntegrationEvent = (event) => {
        const attributes = {
            ...moderationAttributes(event),
            "message": "Approved by user",
            "status": "approved"
        }
        fetchIntegrationEventComplete(event, attributes);
    }

    const rejectIntegrationEvent = (event) => {
        const attributes = {
            ...moderationAttributes(event),
            "message": "Rejected by user",
            "status": "rejected"
        }
        fetchIntegrationEventComplete(event, attributes);
    }

    const currentTextIsNotEqual = () => {
        return newText !== event?.integration_responses[0]?.content_view.responses[0]?.text;
    }

    const changeText = (e) => {
        setNewText(e.target.value);
    }

    const answersHtml = (answers) => {
        return answers.map((event, index) => (
            <Flex key={index} gap='1' p='2' align='center' style={{justifyContent: 'space-between', borderRadius: 'var(--space-2)', background: index % 2 === 0 ? 'var(--gray-2)' : 'var(--gray-1)'}}>
                {(event.type === 'text' || event.type === 'voice' || event.action === 'emoji') && <>
                    <Flex gap='1' align='center' direction='column' style={{alignItems: 'flex-start'}}>
                        {event.action === 'edit' && <Text style={{textDecoration: 'line-through'}} color='indigo' size='2' mr='1'>{event.original_text}</Text>}
                        {event.action === 'delete' && <Text style={{textDecoration: 'line-through'}} color='crimson'>{event.original_text}</Text>}
                        {event.text && <MarkdownText text={event.text} />}
                    </Flex>
                </>}
                {event.type === 'image' && (
                    <Dialog.Root>
                        <Dialog.Trigger>
                            <Avatar src={event.preview}/>
                        </Dialog.Trigger>

                        <Dialog.Content size='1'>
                            <Dialog.Close>
                                <Cross1Icon width='20px' height='20px' />
                            </Dialog.Close>
                            <AspectRatio ratio={2 / 3}>
                                <img src={event.link} alt='Imagem' height='100%' width='100%' />
                            </AspectRatio>
                        </Dialog.Content>
                    </Dialog.Root>
                )}
                {event.type === 'document' && (
                    <Flex gap='1' py='2' align='center' direction='column' style={{alignItems: 'flex-start'}}>
                        <Link href={event.link} target='_blank' size='3'>
                            <Flex align='center' gap='1'>
                                <FileIcon width='20px' height='20px' />
                                <Text>Documento enviado</Text>
                            </Flex>
                        </Link>
                    </Flex>
                )}

                <Flex align='center'>
                    {event.action === 'edit' && <Code color='indigo'>editada</Code>}
                    {event.action === 'delete' && <Code color="crimson">deletada</Code>}
                    <Text ml='2'>
                        {new Date(event.created_at).toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                    </Text>
                </Flex>
            </Flex>
        ));
    }

    return (<Flex gap='1' direction='column' height='100%'>
        {answersHtml(groupedAnswers)}

        <Flex direction='column' height='100%' mt='2'>
            <Separator size='4' />
            <Flex direction='column' gap='1' p='2' mt='2' style={{borderRadius: 'var(--space-2)', backgroundColor: 'var(--green-3)', justifyContent: 'space-between'}}>
                <Flex style={{justifyContent: 'space-between'}}>
                    <Flex gap='1'>
                        <Heading size='2'>Resposta gerada</Heading>
                        {currentTextIsNotEqual(event) && <Code>editada</Code>}
                    </Flex>
                    {!editMode && <Pencil1Icon onClick={setEditMode} width='20px' height='20px' />}
                </Flex>
                {editMode ?
                    <Flex direction="column" gap="3" width='100%'>
                        <TextArea mt='2' resize='both' size='3' style={{height: '100%'}} value={newText} onChange={changeText} />
                    </Flex> :
                    <MarkdownText text={newText} />
                }
            </Flex>

            {!editMode && <Flex mt='2' gap='2' style={{justifyContent: 'center'}}>
                <Button onClick={() => approveIntegrationEvent(event)} loading={moderationLoading}>
                    <Flex align='center' gap='1'>
                        {!currentTextIsNotEqual(event) && <CheckIcon width='20px' height='20px' /> }
                        {currentTextIsNotEqual(event) && <PaperPlaneIcon width='20px' height='20px' />}
                        <Text>Enviar</Text>
                    </Flex>
                </Button>
                <Button color={currentTextIsNotEqual(event) ? 'blue' : 'crimson'} onClick={() => rejectIntegrationEvent(event)}  loading={moderationLoading}>
                    <Flex align='center' gap='1'>
                        {!currentTextIsNotEqual(event) && <>
                            <Cross1Icon width='20px' height='20px' />
                            <Text>Rejeitar</Text>
                        </>}
                        {currentTextIsNotEqual(event) && <>
                            <CheckIcon width='20px' height='20px' />
                            <Text>Corrigir</Text>
                        </>}
                    </Flex>
                </Button>
            </Flex>}

            {editMode && <Flex mt='2' gap='2' style={{justifyContent: 'center'}}>
                <Button color='green' onClick={() => handleEditMode(true)}>
                    <Flex align='center' gap='1'>
                        <CheckIcon width='20px' height='20px' />
                        <Text>Salvar</Text>
                    </Flex>
                </Button>
                <Button color='crimson' onClick={() => handleEditMode(false)}>
                    <Flex align='center' gap='1'>
                        <ResetIcon width='20px' height='20px' />
                        <Text>Resetar</Text>
                    </Flex>
                </Button>
            </Flex>}
        </Flex>
    </Flex>);
}

export { Moderation };