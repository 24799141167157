import { api, headers } from './base';

const creatorInputsNewRequest = async (formData) => {
    return await api.post('/creator_input', formData, headers());
}

const creatorInputsDomainNewRequest = async (formData) => {
    return await api.post('/creator_inputs_domain', formData, headers());
}

const creatorInputsAllRequest = async (creatorName, page, perPage = 10, attrs) => {
    const query = Object.entries(attrs)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    return await api.get(`/creator_input/creator/${creatorName}?page=${page}&per_page=${perPage}&${query}`, headers());
}

const creatorInputsShowRequest = async (id) => {
    return await api.get(`/creator_input/${id}`, headers());
}

const creatorInputsDeleteRequest = async (id) => {
    return await api.delete(`/creator_input/${id}`, headers());
}

export { creatorInputsNewRequest, creatorInputsDomainNewRequest, creatorInputsAllRequest, creatorInputsShowRequest, creatorInputsDeleteRequest };