import { Container, Heading, Flex, Avatar, Progress, Box } from '@radix-ui/themes';
import { useAuth } from '../../hooks/auth';
import { useState } from 'react';
import { DomainStep } from './steps/domain';
import { ProfileStep } from './steps/profile';
import { SuccessStep } from './steps/success';

function WelcomeNew() {
    const { user, updateSession } = useAuth();
    const completeStep = async (step) => {
        setCurrentStep(step);
    }

    const currentStepSelector = () => {
        if (user.has_domain && user.has_profile) {
            return 2;
        } else if (user.has_domain && !user.has_profile) {
            return 1;
        } else {
            return 0;
        }
    }
    const [currentStep, setCurrentStep] = useState(currentStepSelector);

    const titleSelector = (index) => {
        switch (index) {
            case 0:
                return 'Vamos começar!';
            case 1:
                return 'Estamos quase lá!';
            case 2:
            case 3:
                return 'Pronto!';
            default:
                return 'Vamos começar!';
        }
    }

    const colorSelector = (index) => {
        return currentStep >= index ? '' : 'gray';
    }

    const variantColorSelector = (index) => {
        return currentStep >= index ? 'solid' : 'soft';
    }

    const progressBarValueSelector = (index) => {
        return currentStep >= index ? 100 : 0;
    }

    return (
        <Container size='3' p='2'>
            <Flex align='center' p='2' justify='center'>
                <Avatar
                    size="2"
                    src="src"
                    fallback="1"
                    radius="full"
                    variant={variantColorSelector(1)}
                    color={colorSelector(1)}
                />

                <Box width="50px">
                    <Progress size='1' radius='none' variant='soft' value={progressBarValueSelector(1)} />
                </Box>

                <Avatar
                    size="2"
                    src="src"
                    fallback="2"
                    radius="full"
                    variant={variantColorSelector(2)}
                    color={colorSelector(2)}
                />

                <Box width="50px">
                    <Progress size='1' radius='none' variant='soft' value={progressBarValueSelector(2)} />
                </Box>

                <Avatar
                    size="2"
                    src="src"
                    fallback="3"
                    radius="full"
                    variant={variantColorSelector(3)}
                    color={colorSelector(3)}
                />
            </Flex>

            <Heading my='4' align='center'>{titleSelector(currentStep)}</Heading>

            {currentStep === 0 && (<DomainStep user={user} updateSession={updateSession} completeStep={completeStep} />)}
            {currentStep === 1 && (<ProfileStep user={user} updateSession={updateSession} completeStep={completeStep} />)}
            {[2, 3].includes(currentStep) && (<SuccessStep user={user} updateSession={updateSession} completeStep={completeStep} />)}
        </Container>
    );
}

export { WelcomeNew };