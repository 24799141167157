import { Container, Heading, Text, Callout, Button } from '@radix-ui/themes';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';

function ForgotPasswordSuccess() {
    return (
        <Container size='4' maxWidth='920px' p='2' align='center'>
            <Heading>Instruções enviada</Heading>
            <Callout.Root color='blue' mt='4'>
                <Callout.Icon>
                    <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                    <Text>Enviamos um link para redefinir sua senha no seu WhatsApp.</Text>
                </Callout.Text>
            </Callout.Root>

            <Button size="2" mt='4' asChild>
                <Link to="/login" className="Link">
                    Voltar para o login
                </Link>
            </Button>
        </Container>
    );
}

export { ForgotPasswordSuccess }