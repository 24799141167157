import { Box, Button, Callout, Flex, IconButton, Strong, Text, Select, HoverCard, Heading, Switch } from '@radix-ui/themes';
import { InfoCircledIcon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import * as Form from '@radix-ui/react-form';

function ProfileForm(props) {
    const {
        skillCounts,
        setSkillCounts,
        skills,
        setSkills,
        serverErrors,
        setServerErrors,
        errors,
        handleSubmit,
        formData,
        handleChange,
        handleDomainChange,
        accountsDomains,
        loading
    } = props;

    const allowSearchingEntireWebChange = (value) => {
        handleChange(
            {
                target: {
                    name: 'allow_searching_entire_web',
                    value: value
                }
            }
        );
    }

    const deleteTextInput = (e, index) => {
        e.preventDefault();

        if (skillCounts.length === 1) {
            return;
        }

        setSkillCounts(skillCounts.filter((i) => i !== index));

        const skillChanged = { ...skills };
        delete skillChanged[`skills[${index}]`];
        setSkills(skillChanged);
    }

    var handleSkillChange = (e) => {
        skills[e.target.name] = e.target.value;
        setSkillCounts([...skillCounts]);
        setSkills(skills);
    }

    const addTextInput = (e) => {
        e.preventDefault();
        var maxSkills = Math.max(...skillCounts);
        setSkillCounts([...skillCounts, ++maxSkills]);
    }

    var handleDomainValidation = () => {
        if (!formData.domain_name) {
            setServerErrors({ domain_name: true });
        } else {
            setServerErrors({ domain_name: false });
        }
    }

    const SkillInput = (index) => (
        <Form.Field className="FormField" key={index} name={`skills[${index}]`} >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Message className='FormMessage' match="valueMissing">
                    Descreva uma habilidade ou permissão
                </Form.Message>
            </Flex>

            <HoverCard.Root>
                <HoverCard.Trigger>
                    <Flex align="center" style={{ justifyContent: 'space-between' }}>
                        <Form.Control asChild>
                            <input className="Input" type='text' autoComplete='off' value={skills[`skills[${index}]`]} name={`skills[${index}]`} onChange={handleSkillChange} required style={{ width: '100%' }} />
                        </Form.Control>

                        <IconButton size="1" ml='6' color='red' onClick={(e) => deleteTextInput(e, index)} >
                            <TrashIcon width={20} height={20} />
                        </IconButton>
                    </Flex>
                </HoverCard.Trigger>
                {skillsInstructions({left: '860px', top: '-50px'})}
            </HoverCard.Root>
        </Form.Field>
    )

    const textInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='green' />
                    <Box>
                        <Heading size="3" as="h3">
                            Estimulos criativos
                        </Heading>
                        <Text as="div" size="2">
                            - Quais os objetivos dessa IA?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de interação?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de respostas?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de comportamento?
                        </Text>

                        <Text as="div" size="2">
                            - Qual o tom de voz esperado?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as expectativas de personalidade?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    const skillsInstructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="400px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <InfoCircledIcon width='30' height='30' color='green' />
                    <Box>
                        <Heading size="3" as="h3">
                            Habilidades e permissões
                        </Heading>

                        <Text as="div" size="2">
                            - Quais as permissões que essa IA deve ter?
                        </Text>

                        <Text as="div" size="2">
                            - Quais as permissões que essa IA não deve ter?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    return (
        <Box>
            {errors.length > 0 && (
                <Callout.Root mb='4' color="red">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        {errors.map((error, index) => (
                            <Text key={index}>{error}</Text>
                        ))}
                    </Callout.Text>
                </Callout.Root>
            )}

            <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                <Form.Field className="FormField" name="title" >
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Título</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira o título
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild>
                        <input className="Input" type="text" name="title" value={formData?.title} onChange={handleChange} required />
                    </Form.Control>
                </Form.Field>

                <Form.Field className="FormField" name="domain_name" serverInvalid={serverErrors.domain_name}>
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Domínio</Form.Label>

                        <Form.Message className="FormMessage" match="typeMismatch" forceMatch={serverErrors.domain_name}>
                            Você precisa selecionar um domínio
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild >
                        <Select.Root name='domain_name' onValueChange={handleDomainChange} value={formData?.domain_name} defaultValue={formData?.domain_name} required>
                            <Select.Trigger className='Input' placeholder="Selecione uma categoria abaixo"/>
                            <Select.Content position="popper">
                                <Select.Group>
                                    {accountsDomains.map((accountDomain) => (
                                        <Select.Item key={accountDomain.domain.id} value={accountDomain.domain.name}>{accountDomain.domain.name}</Select.Item>
                                    ))}
                                </Select.Group>
                            </Select.Content>
                        </Select.Root>
                    </Form.Control>
                </Form.Field>

                <Form.Field className="FormField" name="text">
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Descrição do perfil</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira o conteúdo
                        </Form.Message>
                    </Flex>

                    <HoverCard.Root>
                        <HoverCard.Trigger>
                            <Form.Control asChild>
                                <textarea className="Textarea" type="text" name="text" value={formData?.text} onChange={handleChange} required />
                            </Form.Control>
                        </HoverCard.Trigger>
                        {textInstructions({left: '860px', top: '-330px'})}
                    </HoverCard.Root>
                </Form.Field>

                <Form.Field className="FormField" name="allow_searching_entire_web">
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Permitir busca na web</Form.Label>
                    </Flex>

                    <Form.Control asChild>
                        <Switch defaultChecked={formData?.allow_searching_entire_web} onCheckedChange={allowSearchingEntireWebChange} />
                    </Form.Control>
                </Form.Field>

                <Flex align="center" style={{ justifyContent: 'space-between' }} mb='2' mt='4'>
                    <Strong>Habilidades desse perfil</Strong>

                    <IconButton size="1" onClick={addTextInput} >
                        <PlusIcon width={20} height={20} />
                    </IconButton>
                </Flex>

                {skillCounts?.map(SkillInput)}

                <Form.Submit asChild>
                    <Button size="2" type="submit" loading={loading} onClick={handleDomainValidation}>
                        Salvar
                    </Button>
                </Form.Submit>
            </Form.Root>
        </Box>
    );
}

export { ProfileForm }