const publicKey = atob(process.env.REACT_APP_PUBLIC_KEY_BASE64);

const encryptWithPublicKey = async (data) => {
    return new Promise((resolve, reject) => {
        const subtle = window.crypto.subtle;
        subtle.importKey(
            "spki",
            convertPemToBinary(publicKey),
            {
                name: "RSA-OAEP",
                hash: {name: "SHA-256"},
            },
            true,
            ["encrypt"]
        ).then((publicKey) => {
            subtle.encrypt(
                {
                    name: "RSA-OAEP"
                },
                publicKey,
                new TextEncoder().encode(JSON.stringify(data))
            ).then((encrypted) => {
                resolve(window.btoa(String.fromCharCode.apply(null, new Uint8Array(encrypted))));
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

const convertPemToBinary = (pem) => {
    let b64Lines = pem.split('\n').filter(line => line.trim().length > 0 && !line.includes('---'));
    let b64Text = b64Lines.join('');
    let binaryDerString = window.atob(b64Text);
    let binaryDer = new Uint8Array(binaryDerString.length);
    for (let i = 0; i < binaryDerString.length; ++i) {
        binaryDer[i] = binaryDerString.charCodeAt(i);
    }
    return binaryDer.buffer;
}

export { encryptWithPublicKey, convertPemToBinary };