import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_HOST;

const api = axios.create({
    baseURL: apiUrl
});

const headers = (attrs = {}) => {
    if (!attrs['Content-Type']) {
        attrs['Content-Type'] = 'application/json';
    }

    attrs['Accept'] = 'application/json';

    if (localStorage.getItem('userData')) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        attrs['Authorization'] = `Bearer ${userData.token}`;
    }

    return { headers: attrs };
};

api.interceptors.response.use(
    response => {
        if ((response.status >= 200 && response.status < 300) || response.status === 304 || response.status === 422 || response.status === 500) {
            const headers = response.headers;

            if (headers['x-token']) {
                const token = headers['x-token'];
                if (token) {
                    const userData = JSON.parse(localStorage.getItem('userData'));
                    localStorage.setItem('userData', JSON.stringify({
                        ...userData,
                        token: token
                    }));
                }
            }
        }

        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userData');
            localStorage.removeItem('accountConfiguration');
            window.location.href = '/login?session=expired';
        }

        return Promise.reject(error);
    }
);

export { api, headers };