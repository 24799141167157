import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Container, Heading, Box, Button, Avatar } from '@radix-ui/themes';
import { ArrowRightIcon, ImageIcon, TrashIcon } from '@radix-ui/react-icons';
import { DragDropFilesForm } from '../../components/form_types/dragdrop_files_form';
import * as Form from '@radix-ui/react-form';
import { useAuth } from '../../hooks/auth';
import { ToastInfo } from '../../components/toast';
import { useNavigate } from 'react-router-dom';

import { accountUpdateRequest } from '../../api/accounts';
import { creatorsMediasByCreatorRequest } from '../../api/creators_medias';
import { INSTAGRAMDOMAINDEFAULT, TIKTOKDOMAINDEFAULT, YOUTUBEDOMAINDEFAULT } from '../../support/constants';

function ConfigurationsEdit() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user, updateSession } = useAuth();
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [imageMissing, setImageMissing] = useState(true);

    const fetchCreatorsMedias = useCallback(() => {
        creatorsMediasByCreatorRequest(user.name).then((response) => {
            let attrs = {}
            response.data.data.forEach((data) => {
                if (data.media.name === 'Instagram') {
                    attrs['instagram'] = INSTAGRAMDOMAINDEFAULT + data.identifier;
                } else if (data.media.name === 'TikTok') {
                    attrs['tiktok'] = TIKTOKDOMAINDEFAULT + data.identifier;
                } else if (data.media.name === 'YouTube') {
                    attrs['youtube'] = YOUTUBEDOMAINDEFAULT + data.identifier;
                }
            });

            setFormData({
                id: user.id,
                identifier: user.name,
                description: user.description,
                instagram: attrs.instagram || INSTAGRAMDOMAINDEFAULT,
                tiktok: attrs.tiktok || TIKTOKDOMAINDEFAULT,
                youtube: attrs.youtube || YOUTUBEDOMAINDEFAULT
            });
        }).catch((error) => {
            console.error(error);
        });
    }, [user]);

    useEffect(() => {
        fetchCreatorsMedias();
    }, [fetchCreatorsMedias]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const medias = {};
        if (formData.tiktok) {
            medias.tiktok = formData.tiktok.replace(TIKTOKDOMAINDEFAULT, '');
        }

        if (formData.instagram) {
            medias.instagram = formData.instagram.replace(INSTAGRAMDOMAINDEFAULT, '');
        }

        if (formData.youtube) {
            medias.youtube = formData.youtube.replace(YOUTUBEDOMAINDEFAULT, '');
        }

        const formDataFile = new FormData();
        formDataFile.append('id', formData.id);
        formDataFile.append('identifier', formData.identifier);
        formDataFile.append('description', formData.description);
        formDataFile.append('files', file);
        formDataFile.append('medias', JSON.stringify(medias));

        accountUpdateRequest(formDataFile).then((response) => {
            let newSession = {
                ...user,
                description: response.data.creator.description,
                image: response.data.creator.image
            };

            updateSession(newSession);
            setLoading(false);

            navigate('/configuracoes');
        }).catch((error) => {
            console.error(error);
            setLoading(false);

            setToastOpen(true);
            setToastMessage('Erro ao atualizar as configurações');
        });
    };

    const updateField = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const updateSocialField = (e) => {
        const socialMediaDomains = {
            youtube: YOUTUBEDOMAINDEFAULT,
            instagram: INSTAGRAMDOMAINDEFAULT,
            tiktok: TIKTOKDOMAINDEFAULT,
        };

        const { name, value } = e.target;
        const domain = socialMediaDomains[name];

        if (value.length >= domain.length) {
            updateField(e);
        }
    }

    const updateImage = (files) => {
        setFile(files[0]);
        setImageMissing(false);
    }

    const removeImage = () => {
        setFormData({
            ...formData,
            files: null
        });
    }

    const mediasInputs = () => {
        const medias = ['TikTok', 'Instagram', 'YouTube'];

        return medias.map((media) => {
            return (
                <Form.Field className="FormField" name={media.toLowerCase()} key={media}>
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>{media}</Form.Label>
                        <Form.Message className='FormMessage' match='typeMismatch'>
                            Insira um {media}
                        </Form.Message>
                    </Flex>

                    <Flex align="center" style={{ justifyContent: 'space-between' }}>
                        <Form.Control asChild>
                            <input type='url' className="Input" onChange={updateSocialField} value={formData[media.toLowerCase()]} name={media.toLowerCase()} required style={{ width: '100%' }} />
                        </Form.Control>
                    </Flex>
                </Form.Field>
            );
        });
    }

    return (
        <Container size="3" align='center' p='2'>
            <ToastInfo
                children={() => {}}
                toastOpen={toastOpen}
                setToastOpen={setToastOpen}
                message={toastMessage}
            />

            <Heading>Editar meus dados</Heading>

            <Box>
                <Flex direction='column' gap='2'>
                    <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                        <Form.Field className="FormField" name='image' serverInvalid={imageMissing}>
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Avatar</Form.Label>
                                <Form.Message className='FormMessage' match='typeMismatch' forceMatch={imageMissing}>
                                    Insira um avatar
                                </Form.Message>
                            </Flex>

                            {file ? (
                                <Flex direction='column' style={{alignItems: 'flex-start'}}>
                                    <Avatar src={URL.createObjectURL(file)} alt='Imagem' size='8' />

                                    <Button mt='2' color='red' onClick={removeImage}>
                                        <TrashIcon width={20} height={20} />
                                        Remover
                                    </Button>

                                </Flex>
                            ) : (
                                <DragDropFilesForm
                                    name='avatar'
                                    type='png'
                                    accept={'.png,jpeg,jpg'}
                                    action={updateImage}
                                    limit={1}
                                    hasError={setToastOpen}
                                    errorMessage={setToastMessage}
                                    autoSubmit={true}
                                    icon={<ImageIcon width={30} height={30} />}
                                />
                            )}
                        </Form.Field>

                        <Form.Field className="FormField" name='description' >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Descrição</Form.Label>
                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira uma descrição
                                </Form.Message>

                                <Form.Message className='FormMessage' match="tooLong">
                                    Máximo de 9999 caracteres
                                </Form.Message>
                            </Flex>

                            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                                <Form.Control disabled={loading} asChild>
                                    <textarea maxLength='9999' className="Textarea" onChange={updateField} value={formData.description} name='description' required style={{ width: '100%' }} />
                                </Form.Control>
                            </Flex>
                        </Form.Field>

                        {mediasInputs()}

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={loading} >
                                Avançar
                                <ArrowRightIcon width={20} height={20} />
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Flex>
            </Box>
        </Container>
    );
}

export { ConfigurationsEdit };