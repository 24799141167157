import { useEffect } from 'react'
import { useAuth } from '../hooks/auth'

function Logout () {
    const { logout } = useAuth()

    useEffect(() => {
        logout()
    }, [logout])

    return null
}

export { Logout }