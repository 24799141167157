import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Strong, Container, Callout } from '@radix-ui/themes';

function EmptyList() {
    return (
        <Container pt='4' pb='4' size='2'>
            <Callout.Root>
                <Callout.Icon>
                    <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                    <Strong>Nenhum registro encontrado</Strong>
                </Callout.Text>
            </Callout.Root>
        </Container>
    );
}

export { EmptyList }