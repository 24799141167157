import React, { useState, useEffect, useCallback } from 'react';
import { Loading } from '../../../components/loading';
import { EmptyList } from '../../../components/empty_list';
import { Pagination } from '../../../components/pagination';
import { Table, Button, AlertDialog, Flex, Checkbox, TextField, Spinner, Select, Box, DropdownMenu } from '@radix-ui/themes';
import { Pencil1Icon, MagnifyingGlassIcon, TrashIcon } from '@radix-ui/react-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { creatorInputsAllRequest, creatorInputsDeleteRequest } from '../../../api/creator_inputs';
import { accountsDomainPrivatesRequest } from '../../../api/accounts_domains';
import { useAuth } from '../../../hooks/auth';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../../contexts/responsive_tools';

function CreatorInputsTable() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    const perPage = 10;
    const [creatorInputs, setCreatorInputs] = useState([]);
    const [creatorInputsLoading, setCreatorInputsLoading] = useState(true);
    const [creatorInputsMeta, setCreatorInputsMeta] = useState({});
    const [creatorInputsCurrentPage, setCreatorInputsCurrentPage] = useState(1);
    const [checkedItems, setCheckedItems] = useState({});
    const [deletingCreatorInput, setDeletingCreatorInput] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [domainsLoading, setDomainsLoading] = useState(true);
    const [domains, setDomains] = useState([]);
    const [searchDomain, setSearchDomain] = useState('');

    const deleteCreatorInput = (e, id) => {
        e.preventDefault();
        creatorInputsDeleteRequest(id).then((_) => {
            fetchCreatorInputs({ preventDefault: () => { } }, creatorInputsCurrentPage);
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchPrivateDomains = useCallback((e, page) => {
        e.preventDefault();
        setDomainsLoading(true);

        accountsDomainPrivatesRequest(user.id, page, perPage).then((response) => {
            setDomains(response.data.data.map(({ domain: { id, name } }) => ({ id, name })));
            setDomainsLoading(false);
        }).catch((error) => {
            console.log(error);
        });
    }, [user]);

    const fetchCreatorInputs = useCallback((e, page, attrs = {}) => {
        e.preventDefault();

        setCreatorInputsLoading(true);
        creatorInputsAllRequest(user.name, page, perPage, attrs).then((response) => {
            setCreatorInputs(response.data.data);

            setCreatorInputsMeta(response.data.meta);
            setCreatorInputsCurrentPage(response.data.meta.page);

            setCreatorInputsLoading(false);
            setLoadingSearch(false);
        }).catch((error) => {
            setCreatorInputsLoading(false);
            setLoadingSearch(false);
        });
    }, [user]);

    const goToShow = (e, id) => {
        e.preventDefault();
        navigate(`/memorias/${id}`);
    }

    const goToEdit = (e, id) => {
        e.preventDefault();
        navigate(`/memorias/${id}/editar`);
    }

    const handleCheckBoxes = (e) => {
        const checkedItem = e.target.dataset.state !== 'checked';

        const newCheckedItems = {};
        creatorInputs.forEach(item => {
            newCheckedItems[item.id] = checkedItem;
        })

        setCheckedItems(newCheckedItems);
    }

    const handleCheckBox = (e) => {
        const id = parseInt(e.target.dataset.id);
        const checkedItem = !checkedItems[id];
        setCheckedItems({ ...checkedItems, [id]: checkedItem });
    }

    useEffect(() => {
        fetchCreatorInputs({ preventDefault: () => { } }, page ? page : 1);
        fetchPrivateDomains({ preventDefault: () => { } }, 1);
    }, [fetchCreatorInputs, fetchPrivateDomains, page]);

    const disableDeleteAllButtons = () => {
        return Object.values(checkedItems).filter(item => item === true).length === 0;
    }

    const deleteSelectCreatorInputs = (e) => {
        const ids = Object.entries(checkedItems).filter(([_, value]) => value === true).map(([key, _]) => key);

        setDeletingCreatorInput(true);

        ids.forEach(id => {
            creatorInputsDeleteRequest(id).catch((error) => {
                console.log(error);
            });
        });

        setTimeout(() => {
            fetchCreatorInputs({ preventDefault: () => { } }, creatorInputsCurrentPage);
            setDeletingCreatorInput(false);
            setCheckedItems({});
        }, 10000);
    }

    const debounceSearch = (func, delay) => {
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            setDebounceTimer(setTimeout(() => func.apply(context, args), delay));
        }
    }

    const search = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.length > 2) {
            setLoadingSearch(true);
            debounceSearch(fetchCreatorInputs, 2000)({ preventDefault: () => { } }, 1, { query: searchQuery, domain_id: searchDomain });
        }

        if (query.length === 0) {
            debounceSearch(fetchCreatorInputs, 2000)({ preventDefault: () => { } }, 1);
        }
    }

    const searchByDomain = (open) => {
        if (!open) {
            debounceSearch(fetchCreatorInputs, 500)({ preventDefault: () => { } }, 1, { query: searchQuery, domain_id: searchDomain });
        }
    };

    return (
         <>
            {creatorInputsLoading && <Loading />}
            {deletingCreatorInput && <Loading />}
            {!creatorInputsLoading && !deletingCreatorInput && (
                <Flex gap={2} mt='4' mb='4' style={{justifyContent: 'space-between'}} display={displayOnlyLaptopsAndDesktops(true)}>
                    <Flex gap={2}>
                        <TextField.Root mr='4' placeholder="Busque pelo título…" value={searchQuery} onChange={search} autoFocus>
                            <TextField.Slot>
                                <MagnifyingGlassIcon height="16" />
                            </TextField.Slot>

                            { loadingSearch && <TextField.Slot>
                                <Spinner />
                            </TextField.Slot> }
                        </TextField.Root>

                        {domainsLoading && <Loading />}
                        {!domainsLoading && (
                            <Select.Root value={searchDomain} onValueChange={setSearchDomain} onOpenChange={searchByDomain}>
                                <Select.Trigger placeholder='Filtrar por domínio' />
                                <Select.Content>
                                    {domains.map(({ id, name }) => (
                                        <Select.Item key={id} value={id}>{name}</Select.Item>
                                    ))}
                                </Select.Content>
                            </Select.Root>
                        )}
                    </Flex>

                    { creatorInputs.length > 0 && (<AlertDialog.Root>
                        <AlertDialog.Trigger>
                            <Button disabled={disableDeleteAllButtons()} color='red'>
                                <TrashIcon /> Deletar selecionados
                            </Button>
                        </AlertDialog.Trigger>

                        <AlertDialog.Content maxWidth="450px">
                            <AlertDialog.Title>Deletar selecionados</AlertDialog.Title>
                            <AlertDialog.Description size="2">
                                Você tem certeza que deseja deletar as memórias selecionadas?
                            </AlertDialog.Description>

                            <Flex gap="3" mt="4" justify="end">
                                <AlertDialog.Cancel>
                                    <Button variant="soft" color="gray">
                                        Cancel
                                    </Button>
                                </AlertDialog.Cancel>
                                <AlertDialog.Action>
                                    <Button variant="solid" color="red" onClick={deleteSelectCreatorInputs}>
                                        Deletar
                                    </Button>
                                </AlertDialog.Action>
                            </Flex>
                        </AlertDialog.Content>
                    </AlertDialog.Root>) }
                </Flex>
            )}
            {!creatorInputsLoading && creatorInputs.length === 0 && <EmptyList />}
            {!creatorInputsLoading && !deletingCreatorInput && creatorInputs.length > 0 && (
                <>
                    <Box display={displayOnlyLaptopsAndDesktops()}>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>
                                        <Checkbox onClick={handleCheckBoxes} />
                                    </Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Título</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Domínios</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Utilização</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>Criado em</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {creatorInputs.map((creatorInput) => (
                                    <Table.Row key={creatorInput.id}>
                                        <Table.RowHeaderCell>
                                            <Checkbox data-id={creatorInput.id} checked={!!checkedItems[creatorInput.id]} onClick={handleCheckBox} />
                                        </Table.RowHeaderCell>
                                        <Table.RowHeaderCell>
                                            {creatorInput.title.substring(0, 50) + (creatorInput.title.length > 50 ? '...' : '')}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>
                                            {creatorInput.domains ? creatorInput.domains.map((d) => d.name).join(', ') : 'Sem domínios'}
                                        </Table.Cell>
                                        <Table.Cell>{creatorInput.used_by_content_views_count}</Table.Cell>
                                        <Table.Cell>{new Date(creatorInput.created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                        <Table.Cell>
                                            <Button size="1" mr='2' onClick={(e) => goToEdit(e, creatorInput.id)}>
                                                <Pencil1Icon width={20} height={20} />
                                            </Button>

                                            <Button size="1" mr='2' onClick={(e) => goToShow(e, creatorInput.id)}>
                                                <MagnifyingGlassIcon width={20} height={20} />
                                            </Button>

                                            <AlertDialog.Root>
                                                <AlertDialog.Trigger>
                                                    <Button size="1" mr='2'>
                                                        <TrashIcon width={20} height={20} />
                                                    </Button>
                                                </AlertDialog.Trigger>
                                                <AlertDialog.Content maxWidth="450px">
                                                    <AlertDialog.Title>Deletar</AlertDialog.Title>
                                                    <AlertDialog.Description size="2">
                                                        Você tem certeza que deseja deletar esta memória?
                                                    </AlertDialog.Description>

                                                    <Flex gap="3" mt="4" justify="end">
                                                        <AlertDialog.Cancel>
                                                            <Button variant="soft" color="gray">
                                                                Cancel
                                                            </Button>
                                                        </AlertDialog.Cancel>
                                                        <AlertDialog.Action>
                                                            <Button variant="solid" color="red" onClick={(e) => deleteCreatorInput(e, creatorInput.id)}>
                                                                Deletar
                                                            </Button>
                                                        </AlertDialog.Action>
                                                    </Flex>
                                                </AlertDialog.Content>
                                            </AlertDialog.Root>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </Box>

                    <Box display={displayOnlyMobiles()}>
                        <Table.Root>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeaderCell>Título</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {creatorInputs.map((creatorInput) => (
                                    <Table.Row key={creatorInput.id}>
                                        <Table.RowHeaderCell>
                                            {creatorInput.title.substring(0, 30) + (creatorInput.title.length > 30 ? '...' : '')}
                                        </Table.RowHeaderCell>
                                        <Table.Cell>
                                            <DropdownMenu.Root>
                                                <DropdownMenu.Trigger>
                                                    <Button variant='soft'>
                                                        ...
                                                        <DropdownMenu.TriggerIcon />
                                                    </Button>
                                                </DropdownMenu.Trigger>

                                                <DropdownMenu.Content>
                                                    <DropdownMenu.Item onSelect={(e) => goToEdit(e, creatorInput.id)}>
                                                        <Pencil1Icon width={20} height={20} />
                                                        Editar
                                                    </DropdownMenu.Item>

                                                    <DropdownMenu.Item onSelect={(e) => goToShow(e, creatorInput.id)}>
                                                        <MagnifyingGlassIcon width={20} height={20} />
                                                        Visualizar
                                                    </DropdownMenu.Item>

                                                    <AlertDialog.Root>
                                                        <AlertDialog.Trigger>
                                                            <DropdownMenu.Item onSelect={(e) => deleteCreatorInput(e, creatorInput.id)}>
                                                                <TrashIcon width={20} height={20} />
                                                                Deletar
                                                            </DropdownMenu.Item>
                                                        </AlertDialog.Trigger>
                                                        <AlertDialog.Content maxWidth="450px">
                                                            <AlertDialog.Title>Deletar</AlertDialog.Title>
                                                            <AlertDialog.Description size="2">
                                                                Você tem certeza que deseja deletar esta memória?
                                                            </AlertDialog.Description>

                                                            <Flex gap="3" mt="4" justify="end">
                                                                <AlertDialog.Cancel>
                                                                    <Button variant="soft" color="gray">
                                                                        Cancel
                                                                    </Button>
                                                                </AlertDialog.Cancel>
                                                                <AlertDialog.Action>
                                                                    <Button variant="solid" color="red" onClick={(e) => deleteCreatorInput(e, creatorInput.id)}>
                                                                        Deletar
                                                                    </Button>
                                                                </AlertDialog.Action>
                                                            </Flex>
                                                        </AlertDialog.Content>
                                                    </AlertDialog.Root>
                                                </DropdownMenu.Content>
                                            </DropdownMenu.Root>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </Box>

                    <Pagination meta={creatorInputsMeta} requestPerPage={fetchCreatorInputs} requestPerPageArgs={{ query: searchQuery, domain_id: searchDomain }} />
                </>
            )}

        </>
    );
}

export { CreatorInputsTable }