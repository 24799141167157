import { Flex } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';

function IntegrationFormTelegram(props) {
    const { formData, setFormData } = props;

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                [name]: value
            })
        }));
    }

    return (
        <Form.Field className="FormField" name="phone" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Número Celular</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o celular
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="text" name="phone" value={JSON.parse(formData?.configurations).phone} onChange={handleChange} required />
            </Form.Control>
        </Form.Field>

    );
}

export { IntegrationFormTelegram }