import { Flex, Heading, Text, Link as LinkUI } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { Share1Icon } from '@radix-ui/react-icons';

function Logo(props) {
    const { withText = true, clicable = true } = props;

    const logoHtml = (
        <Heading>
            <Flex gap='2' align='center'>
                <Share1Icon width='20' height='20' />
                {withText && <Text>WITHUB</Text>}
            </Flex>
        </Heading>
    );

    return (
        <>
            { clicable && <LinkUI asChild>
                <Link to='/'>{logoHtml}</Link>
            </LinkUI>}

            { !clicable && logoHtml }
        </>
    );
}

export { Logo }