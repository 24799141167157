import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileTextIcon, MixIcon, VideoIcon, SpeakerLoudIcon, TextIcon, Link1Icon } from '@radix-ui/react-icons'
import {
  Box,
  Text,
  Tabs
} from '@radix-ui/themes';

import { DragDropFilesForm } from '../../components/form_types/dragdrop_files_form';
import { TextForm } from './form_types/text_form';
import { LinksForm } from './form_types/links_form';
import { useLocation } from 'react-router-dom';
import { ToastInfo } from '../../components/toast';

function ContentViewsForm(props) {
  const navigate = useNavigate();
  const { tabs } = props;
  const location = useLocation();
  const textOfState = location.state ? location.state.text : '';
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const goTo = (files) => {
    navigate('/memorias/nova/categorizar', { state: { files: files, text: null, links: [] } });
  }

  return (
    <Tabs.Root defaultValue="text">
      <ToastInfo
          children={() => {}}
          toastOpen={toastOpen}
          setToastOpen={setToastOpen}
          message={toastMessage}

      />

      <Tabs.List>
        <Tabs.Trigger value="text" style={{display: !tabs.includes('text') ? 'none' : 'block'}}>
          <TextIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>Texto</Text>
        </Tabs.Trigger>
        <Tabs.Trigger value="documents" style={{display: !tabs.includes('documents') ? 'none' : 'block'}}>
          <FileTextIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>Documentos</Text>
        </Tabs.Trigger>
        <Tabs.Trigger value="powerpoint" style={{display: !tabs.includes('powerpoint') ? 'none' : 'block'}}>
          <MixIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>PowerPoint</Text>
        </Tabs.Trigger>
        <Tabs.Trigger value="video" style={{display: !tabs.includes('video') ? 'none' : 'block'}}>
          <VideoIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>Vídeo</Text>
        </Tabs.Trigger>
        <Tabs.Trigger value="audio" style={{display: !tabs.includes('audio') ? 'none' : 'block'}}>
          <SpeakerLoudIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>Áudio</Text>
        </Tabs.Trigger>
        <Tabs.Trigger value="link" style={{display: !tabs.includes('link') ? 'none' : 'block'}}>
          <Link1Icon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
          <Text>Link</Text>
        </Tabs.Trigger>
      </Tabs.List>

      <Box pt="3">
        <Tabs.Content value="text" style={{display: !tabs.includes('text') ? 'none' : 'block'}}>
          <TextForm name='texto' type='text' textOfState={textOfState} icon={<TextIcon width={30} height={30} />} />
        </Tabs.Content>

        <Tabs.Content value="documents" style={{display: !tabs.includes('documents') ? 'none' : 'block'}}>
          <DragDropFilesForm
            name='documentos'
            type='docx'
            accept={'.doc,.docx,.txt,.pdf,application/pdf'}
            action={goTo}
            limit={5}
            hasError={setToastOpen}
            errorMessage={setToastMessage}
            icon={<FileTextIcon width={30} height={30} />}
          />
        </Tabs.Content>

        <Tabs.Content value="powerpoint" style={{display: !tabs.includes('powerpoint') ? 'none' : 'block'}}>
          <DragDropFilesForm
            name='powerpoints'
            type='ppt'
            accept={'.ppt,.pptx'}
            action={goTo}
            limit={5}
            hasError={setToastOpen}
            errorMessage={setToastMessage}
            icon={<MixIcon width={30} height={30} />}
          />
        </Tabs.Content>

        <Tabs.Content value="video" style={{display: !tabs.includes('video') ? 'none' : 'block'}}>
          <DragDropFilesForm
            name='videos'
            type='mp4'
            accept={'.mp4,video/mp4'}
            action={goTo}
            limit={5}
            hasError={setToastOpen}
            errorMessage={setToastMessage}
            icon={<VideoIcon width={30} height={30} />}
          />
        </Tabs.Content>

        <Tabs.Content value="audio" style={{display: !tabs.includes('audio') ? 'none' : 'block'}}>
          <DragDropFilesForm
            name='audios'
            type='mp3'
            accept={'.mp3,.wav,.webm'}
            action={goTo}
            limit={5}
            hasError={setToastOpen}
            errorMessage={setToastMessage}
            icon={<SpeakerLoudIcon width={30} height={30} />}
          />
        </Tabs.Content>

        <Tabs.Content value="link" style={{display: !tabs.includes('link') ? 'none' : 'block'}}>
          <LinksForm name='link' type='link' icon={<Link1Icon width={30} height={30} />} />
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
}

export { ContentViewsForm };