import { Flex, Select, IconButton, Heading } from '@radix-ui/themes';
import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import * as Form from '@radix-ui/react-form';

function IntegrationFormWebHook(props) {
    const { formData, setFormData } = props;

    const headers = () => {
        return JSON.parse(formData?.configurations).headers;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                [name]: value
            })
        }));
    }

    const changeMethod = (value) => {
        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                method: value
            })
        }));
    }

    const deleteHeader = (index) => {
        if (headers.length === 1) {
            return;
        }

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                headers: headers().filter((_, i) => i !== index)
            })
        }));
    }

    const addHeader = (e) => {
        e.preventDefault();
        handleChange({
            target: {
                name: 'headers',
                value: [...headers(), { key: '', value: '' }]
            }
        });
    }

    const changeHeaderKey = (event) => {
        const { name, value } = event.target;
        const index = name.match(/\d+/)[0];

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                headers: headers().map((header, i) => i === Number(index) ? { ...header, key: value } : header)
            })
        }));
    }

    const changeHeaderValue = (event) => {
        const { name, value } = event.target;
        const index = name.match(/\d+/)[0];

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                headers: JSON.parse(prev.configurations).headers.map((header, i) => i === Number(index) ? { ...header, value: value } : header)
            })
        }));
    }

    const BuildHeader = (index, attrs) => (
        <Form.Field className="FormField" key={index} >
            <Form.Message className="FormMessage" match="valueMissing">
                Insira um Header
            </Form.Message>

            <Flex gap='3' align="center" style={{ justifyContent: 'space-between' }}>
                <Form.Control asChild>
                    <input className="Input" type='link' value={attrs['key']} name={`header_key[${index}]`} onChange={changeHeaderKey} required style={{ width: '100%' }} />
                </Form.Control>

                <Form.Control asChild>
                    <input className="Input" type='link' value={attrs['value']} name={`header_value[${index}]`} onChange={changeHeaderValue} required style={{ width: '100%' }} />
                </Form.Control>

                <IconButton size="1" ml='6' color='red' onClick={() => deleteHeader(index)} >
                    <TrashIcon width={20} height={20} />
                </IconButton>
            </Flex>
        </Form.Field>
    )


    return (
        <>
            <Form.Field className="FormField" name="url" >
                <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                    <Form.Label className='FormLabel'>URL</Form.Label>

                    <Form.Message className='FormMessage' match="valueMissing">
                        Insira a URL
                    </Form.Message>
                </Flex>

                <Form.Control asChild>
                    <input className="Input" type="text" name="url" value={JSON.parse(formData?.configurations).url} onChange={handleChange} required />
                </Form.Control>
            </Form.Field>

            <Form.Field className="FormField" name="method" >
                <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                    <Form.Label className='FormLabel'>Método</Form.Label>

                    <Form.Message className='FormMessage' match="valueMissing">
                        Insira a método
                    </Form.Message>
                </Flex>

                <Form.Control asChild>
                    <Select.Root defaultValue="GET" value={JSON.parse(formData?.configurations).method} onValueChange={changeMethod}>
                        <Select.Trigger className="SelectTrigger" />
                        <Select.Content className="SelectContent">
                            <Select.Item className="SelectItem" value="GET">GET</Select.Item>
                            <Select.Item className="SelectItem" value="POST">POST</Select.Item>
                            <Select.Item className="SelectItem" value="PUT">PUT</Select.Item>
                            <Select.Item className="SelectItem" value="DELETE">DELETE</Select.Item>
                        </Select.Content>
                    </Select.Root>
                </Form.Control>
            </Form.Field>

            <Flex gap='2' style={{justifyContent: 'space-between', alignItems: 'center'}}>
                <Heading mt='3' mb='3' size='2'>Headers</Heading>
                <IconButton size="1" ml='2' color='ascent' onClick={addHeader} >
                    <PlusIcon width={20} height={20} />
                </IconButton>
            </Flex>
            {headers().map((attrs, index) => BuildHeader(index, attrs))}
        </>
    );
}

export { IntegrationFormWebHook }