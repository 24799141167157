import { useState } from 'react';
import { Container, Heading } from '@radix-ui/themes';
import { ScheduleIntegrationsForm } from './form';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';

import { scheduleIntegrationCreateRequest } from '../../api/schedule_integrations';

function ScheduleIntegrationNew() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [errors] = useState([]);
    const [loading] = useState(false);

    const handleFrequencies = (frequency_hour, frequency_days_of_week) => {

        return `0 ${frequency_hour} * * ${frequency_days_of_week}`
    }

    const handleSubmit = async (e, formData) => {
        e.preventDefault();

        const body = {
            integration_id: formData.integration_id,
            account_id: user.id,
            cron: handleFrequencies(formData.frequency_hour, formData.frequency_days_of_week),
            configurations: JSON.stringify({
                text: formData.text,
                thread: formData.thread,
                domain_name: formData.domain_name,
                profile_title: formData.profile_title,
                creator_identifier: formData.creator_identifier,
            })
        }

        scheduleIntegrationCreateRequest(body).then(response => {
            navigate('/acoes');
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <Container size='3' p='2'>
            <Heading>Inserir nova ação</Heading>

            <ScheduleIntegrationsForm
                integration={{}}
                errors={errors}
                handleSubmit={handleSubmit}
                loading={loading}/>
        </Container>
    );
}

export { ScheduleIntegrationNew }