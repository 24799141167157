import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Heading, Box, Flex, Button, Link as LinkUI, Separator, Callout, Text } from '@radix-ui/themes';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import * as Form from '@radix-ui/react-form';
import { forgotPasswordRequest } from '../../api/accounts';
import { encryptWithPublicKey } from '../../support/cryptography';
import { Navigate } from "react-router-dom";

function ForgotPasswordNew() {
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        iat: ''
    });

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            iat: new Date().toISOString()
        });
    }

    var handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);

        setFormData({
            ...formData,
            iat: new Date().toISOString()
        });

        encryptWithPublicKey(formData).then((data) => {
            forgotPasswordRequest({
                hash: data
            }).then((response) => {
                setIsLoading(false);
                setSuccess(true);
            }).catch((error) => {
                setIsLoading(false);
                setErrors(['Ocorreu um erro ao enviar o email de recuperação de senha']);
            });
        });
    }

    return (
        <Container size='4' maxWidth='920px' p='2' align='center'>
            {success && <Navigate to="/recuperar_senha/sucesso" replace={true} />}
            <Heading>Recuperar a senha</Heading>

            <Flex direction='column'>
                <Box>
                    {errors.length > 0 && (
                        <Callout.Root mb='4' color="red">
                            <Callout.Icon>
                                <InfoCircledIcon />
                            </Callout.Icon>
                            <Callout.Text>
                                {errors.map((error, index) => (
                                    <Text key={index}>{error}</Text>
                                ))}
                            </Callout.Text>
                        </Callout.Root>
                    )}
                    <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                        <Form.Field className="FormField" name="email" >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Email</Form.Label>

                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira o email
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="email" name="email" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={isLoading} >
                                Enviar instruções
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Box>

                <Flex justify='start' mt='4'>
                    <Box pr='2'>
                        <LinkUI asChild>
                            <Link to='/cadastro'>Criar conta</Link>
                        </LinkUI>
                    </Box>
                    <Separator orientation='vertical' />
                    <Box pl='2'>
                        <LinkUI asChild>
                            <Link to='/login'>Login</Link>
                        </LinkUI>
                    </Box>
                </Flex>
            </Flex>
        </Container>
    );
}

export { ForgotPasswordNew }