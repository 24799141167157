import { Container, Heading, Strong, DataList, Box, Text, Flex } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { profileShowRequest } from '../../api/profiles';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function ProfileShow() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({})

    useEffect(() => {
        const fetchProfile = () => {
            setLoading(true);
            profileShowRequest(id).then((response) => {
                setProfile(response.data);
                setLoading(false);
            });
        }

        fetchProfile();
    }, [id]);

    return (
        <Container size='4' p='2'>
            <Heading>Detalhe do perfil</Heading>

            {loading && (<Text>Carregando...</Text>)}
            {!loading && !profile && (<Text>Não foi possível carregar o perfil</Text>)}
            {!loading && profile && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <DataList.Root mt='4'>
                        <DataList.Item>
                            <DataList.Label>Título</DataList.Label>
                            <DataList.Value>{profile.title}</DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                            <DataList.Label>Descrição do perfil</DataList.Label>
                            <DataList.Value>{profile.text}</DataList.Value>
                        </DataList.Item>

                        <Heading size='2' mt='4'>Habilidades/Permissões</Heading>

                        {profile.skills && profile.skills.map((skill, index) => (
                            <DataList.Item key={index}>
                                <DataList.Label>#{index + 1} - [{skill.level}]</DataList.Label>
                                <DataList.Value>{skill.text}</DataList.Value>
                            </DataList.Item>
                        ))}
                    </DataList.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Flex gap='4' mt='4' direction='column'>
                        <Strong>Título:</Strong>
                        <Text>{profile.title}</Text>

                        <Strong>Descrição do perfil:</Strong>
                        <Text>{profile.text}</Text>

                        <Heading size='2'>Habilidades/Permissões</Heading>

                        {profile.skills && profile.skills.map((skill, index) => (
                            <Box key={index} mt='2'>
                                <Strong key={index}>#{index + 1} - [{skill.level}]</Strong>
                                <Text>{skill.text}</Text>
                            </Box>
                        ))}
                    </Flex>
                </Box>
            </>}
        </Container>
    )
}

export { ProfileShow }