import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { Container, Heading } from '@radix-ui/themes';
import { accountsDomainAllRequest } from '../../api/accounts_domains';
import { profilesCreateRequest } from '../../api/profiles';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProfileForm } from './form';

function ProfileNew() {
    const navigate = useNavigate();
    const location = useLocation();
    const [skills, setSkills] = useState({});
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);
    const [formData, setFormData] = useState({
        creator_identifier: user.name,
        domain_name: '',
        title: '',
        text: ''
    });

    const [accountsDomains, setAccountsDomains] = useState([]);
    const [skillCounts, setSkillCounts] = useState([0]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const domainName = queryParams.get('domainName');
        if (!domainName) return;
        if (formData.domain_name === domainName) return;

        setFormData({...formData, domain_name: domainName });
    }, [location.search, formData]);

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    var handleDomainChange = (value) => {
        setServerErrors({ domain_name: false });
        setFormData({
            ...formData,
            domain_name: value
        });
    }

    var handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        var body = {
            ...formData,
            skills: Object.values(skills)
        };

        profilesCreateRequest(body).then((response) => {
            setLoading(false);
            navigate('/perfis')
        }).catch((error) => {
            setErrors([error.response.data.error]);
            setLoading(false);
        });

        setLoading(false);
    }

    useEffect(() => {
        var fetchDomains = () => {
            accountsDomainAllRequest(user.id, 1, 999).then((response) => {
                setAccountsDomains(response.data.data);
            })
        }
        fetchDomains();
    }, [user]);


    return (
        <Container size='3' p='2'>
            <Heading>Inserir novo perfil</Heading>
            <ProfileForm
                errors={errors}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleDomainChange={handleDomainChange}
                accountsDomains={accountsDomains}
                skills={skills}
                setSkills={setSkills}
                serverErrors={serverErrors}
                setServerErrors={setServerErrors}
                skillCounts={skillCounts}
                setSkillCounts={setSkillCounts}
                formData={formData}
                loading={loading} />
        </Container>
    );
}

export { ProfileNew }