import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Heading, Flex, Strong, Text, Box, Table, Button } from '@radix-ui/themes';
import { DotFilledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { scheduleIntegrationShowRequest } from '../../api/schedule_integrations';

import { dataIntegrationsRequest } from '../../api/data_integrations';

import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';
import { Pagination } from '../../components/pagination';
import { MarkdownText } from '../../components/markdown_text';

function ScheduleIntegrationsShow() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [dataIntegrationsLoading, setDataIntegrationsLoading] = useState(true);
    const [dataIntegrations, setDataIntegrations] = useState([]);
    const [dataIntegrationsMeta, setDataIntegrationsMeta] = useState({});
    const [scheduleIntegration, setScheduleIntegration] = useState({});

    const fetchScheduleIntegration = useCallback(async () => {
        setLoading(true);
        scheduleIntegrationShowRequest(id).then(response => {
            setScheduleIntegration(response.data);
            setLoading(false);
        }).catch(error => {
            console.log(error);
        });
    }, [id]);

    const fetchDataIntegrations = useCallback(async (e, page) => {
        e.preventDefault();
        setDataIntegrationsLoading(true);
        dataIntegrationsRequest(id, page).then(response => {
            setDataIntegrations(response.data.data);
            setDataIntegrationsMeta(response.data.meta);
            setDataIntegrationsLoading(false);
        }).catch(error => {
            console.log(error);
        });
    }, [id]);


    useEffect(() => {
        fetchScheduleIntegration();
        fetchDataIntegrations({ preventDefault: () => { } }, 1);
    }, [fetchScheduleIntegration, fetchDataIntegrations]);

    const dayOfWeek = (day) => {
        switch (day) {
            case '0':
                return 'Domingo';
            case '1':
                return 'Segunda-feira';
            case '2':
                return 'Terça-feira';
            case '3':
                return 'Quarta-feira';
            case '4':
                return 'Quinta-feira';
            case '5':
                return 'Sexta-feira';
            case '6':
                return 'Sábado';
            default:
                return '';
        }
    }

    const handleFrequency = (cronStatement) => {
        const frequency = cronStatement.split(' ');
        const hour = frequency[1] === '*' ? 'de hora em hora' : `às ${frequency[1]}:00`;
        const days = frequency[4] === '*' ? 'Todos os dias' : `Todas as ${dayOfWeek(frequency[4])}`;
        return `${days}, ${hour}`;
    }

    const goToShow = (e, id) => {
        e.preventDefault();
        navigate(`/detalhe-integracao/${id}`);
    }

    return (
        <Container size='4' p='2'>
            <Heading>Detalhe da integração</Heading>

            <Box>
                {loading && <Loading />}
                {!loading && !scheduleIntegration && <EmptyList />}
                {!loading && scheduleIntegration && (
                    <Flex direction='column' gap='4' my='4'>
                        <Flex direction='column' gap='1'>
                            <Strong>Pergunta</Strong>
                            <Text>{JSON.parse(scheduleIntegration.configurations).text}</Text>
                        </Flex>

                        <Flex direction='column' gap='1'>
                            <Strong>Dominio</Strong>
                            <Text>{JSON.parse(scheduleIntegration.configurations).domain_name}</Text>
                        </Flex>

                        <Flex direction='column' gap='1'>
                            <Strong>Profile</Strong>
                            <Text>{JSON.parse(scheduleIntegration.configurations).profile_title}</Text>
                        </Flex>

                        <Flex direction='column' gap='1'>
                            <Strong>Integração</Strong>
                            <Text>{scheduleIntegration.integration.name}</Text>
                        </Flex>

                        <Flex direction='column' gap='1'>
                            <Strong>Frequência</Strong>
                            <Text>{handleFrequency(scheduleIntegration.cron)}</Text>
                        </Flex>

                        <Flex direction='column' gap='1' maxHeight='90%'>
                            <Strong>Configurações</Strong>
                            <MarkdownText text={'```json\n' + scheduleIntegration.configurations + '\n```\n'} />
                        </Flex>
                    </Flex>
                )}
            </Box>

            <Heading mt='2'>Integrações realizadas</Heading>
            <Box>
                {dataIntegrationsLoading && <Loading />}
                {!dataIntegrationsLoading && dataIntegrations.length === 0 && <EmptyList />}
                {!dataIntegrationsLoading && dataIntegrations.length > 0 && (
                    <Table.Root size='3'>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>ID</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {dataIntegrations.map((dataIntegration) => (
                                <Table.Row key={dataIntegration.id}>
                                    <Table.RowHeaderCell>
                                        {JSON.parse(dataIntegration.data).status_code === 200 ? <DotFilledIcon color='green' /> : <DotFilledIcon color='red' />}
                                    </Table.RowHeaderCell>
                                    <Table.Cell>{dataIntegration.id}</Table.Cell>
                                    <Table.Cell>{JSON.parse(dataIntegration.data).status_code}</Table.Cell>
                                    <Table.Cell>
                                        <Button size="1" mr='2' onClick={(e) => goToShow(e, dataIntegration.id)}>
                                            <MagnifyingGlassIcon width={20} height={20} />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                )}

                <Pagination meta={dataIntegrationsMeta} requestPerPage={fetchDataIntegrations} />
            </Box>
        </Container>
    );
}

export { ScheduleIntegrationsShow }