import { api, headers } from './base';

const contentViewsCreateRequest = async (formData) => {
    return await api.post('/content_view', formData, headers());
}

const contentViewsListByCreatorRequest = async (creatorId, page, perPage = 10) => {
    return await api.get(`/content_view/creator/${creatorId}?page=${page}&per_page=${perPage}`, headers());
}

const contentViewsShowRequest = async (id) => {
    return await api.get(`/content_view/${id}`, headers());
}

const contentViewPublicShowRequest = async (short_path) => {
    return await api.get(`/content_view/public/${short_path}?page=1&per_page=10`);
}

const contentViewPublicByDomainRequest = async (domain, page, perPage) => {
    return await api.get(`/content_view/public/domain/${domain}?page=${page}&per_page=${perPage}`);
}

const contentViewThreadRequest = async (id, page, perPage) => {
    return await api.get(`/content_view/thread/${id}?page=${page}&per_page=${perPage}`, headers());
}

const contentViewThreadTitlesRequest = async (creatorName, page, perPage) => {
    return await api.get(`/content_view/thread_titles/${creatorName}?page=${page}&per_page=${perPage}&from_playground=true`, headers());
}

const contentViewArchiveRequest = async (id) => {
    return await api.post(`/content_view/${id}/archive`, {}, headers());
}

const contentViewMarkAsPublicRequest = async (id) => {
    return await api.post(`/content_view/${id}/mark_as_public`, {}, headers());
}

const contentViewMarkAsPrivateRequest = async (id) => {
    return await api.post(`/content_view/${id}/mark_as_private`, {}, headers());
}

const contentViewUpdateRequest = async (id, formData) => {
    return await api.put(`/content_view/${id}`, formData, headers());
}

export { contentViewPublicByDomainRequest, contentViewPublicShowRequest, contentViewUpdateRequest, contentViewMarkAsPublicRequest, contentViewMarkAsPrivateRequest, contentViewArchiveRequest, contentViewsCreateRequest, contentViewsListByCreatorRequest, contentViewsShowRequest, contentViewThreadRequest, contentViewThreadTitlesRequest }