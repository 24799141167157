import React, { useEffect, useState, useCallback } from 'react';
import { MagnifyingGlassIcon, Pencil1Icon, TrashIcon, PlusIcon } from '@radix-ui/react-icons';
import { Container, Flex, Heading, Button, Table, IconButton, Box, DropdownMenu } from '@radix-ui/themes';
import { Pagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { integrationIndexRequest, integrationDeleteRequest } from '../../api/integrations';
import { Loading } from '../../components/loading';
import { EmptyList } from '../../components/empty_list';
import { ButtonWithAlert } from '../../components/button_with_alert';
import { useNavigate } from 'react-router-dom';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

import { ToastInfo } from '../../components/toast';

function IntegrationIndex() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [integrations, setIntegrations] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const perPage = 10;

    const fetchDatas = useCallback((e, page) => {
        e.preventDefault();
        setLoading(true);

        integrationIndexRequest(user.id, page, perPage).then((response) => {
            setIntegrations(response.data.data);
            setMeta(response.data.meta);
            setLoading(false);
        })
    }, [user]);

    useEffect(() => {
        fetchDatas({ preventDefault: () => { } }, 1);
    }, [fetchDatas]);

    const goToShow = (e, id) => {
        e.preventDefault();
        navigate(`/integracoes/${id}`);
    }

    const goToEdit = (e, id) => {
        e.preventDefault();
        navigate(`/integracoes/${id}/editar`);
    }

    const goToDelete = (_, id) => {
        integrationDeleteRequest(id).then((response) => {
            fetchDatas({ preventDefault: () => { } }, 1);
        }).catch((error) => {
            setToastOpen(true);
            setToastMessage('Esta integração não pode ser excluída, pois está sendo utilizada em alguma(s) ação(ões).')
        });
    }

    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='6' align='center'>
                <Heading size={{initial: '5', xs: '5'}}>Minhas integrações</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/integracoes/nova'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Inserir nova integração
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <PlusIcon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            {loading && <Loading />}
            {!loading && integrations.length === 0 && <EmptyList />}
            {!loading && integrations.length > 0 && <>
                <Box display={displayOnlyLaptopsAndDesktops()}>
                    <Table.Root size='3'>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Tipo</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {integrations.map((integration) => (
                                <Table.Row key={integration.id}>
                                    <Table.Cell>{integration.type}</Table.Cell>
                                    <Table.Cell>{integration.name}</Table.Cell>
                                    <Table.Cell>
                                        <IconButton size="1" mr='2' onClick={(e) => { goToEdit(e, integration.id) }} >
                                            <Pencil1Icon width={20} height={20} />
                                        </IconButton>

                                        <IconButton size="1" mr='2' onClick={(e) => { goToShow(e, integration.id) }} >
                                            <MagnifyingGlassIcon width={20} height={20} />
                                        </IconButton>

                                        <ToastInfo
                                            children={
                                                <ButtonWithAlert
                                                    callback={goToDelete}
                                                    callbackParams={integration.id}
                                                    children={
                                                        <IconButton size="1" mr='2'>
                                                            <TrashIcon width={20} height={20} />
                                                        </IconButton>
                                                    }
                                                />
                                            }
                                            toastOpen={toastOpen}
                                            setToastOpen={setToastOpen}
                                            message={toastMessage}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>

                <Box display={displayOnlyMobiles()}>
                    <Table.Root size='2'>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColumnHeaderCell>Nome</Table.ColumnHeaderCell>
                                <Table.ColumnHeaderCell>Ação</Table.ColumnHeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {integrations.map((integration) => (
                                <Table.Row key={integration.name}>
                                    <Table.Cell>{integration.name}</Table.Cell>
                                    <Table.Cell>
                                        <DropdownMenu.Root>
                                            <DropdownMenu.Trigger>
                                                <Button variant='soft'>
                                                    ...
                                                    <DropdownMenu.TriggerIcon />
                                                </Button>
                                            </DropdownMenu.Trigger>

                                            <DropdownMenu.Content>
                                                <DropdownMenu.Item onSelect={(e) => { goToEdit(e, integration.id) }}>
                                                    <Pencil1Icon width={20} height={20} />
                                                    Editar
                                                </DropdownMenu.Item>

                                                <DropdownMenu.Item onSelect={(e) => { goToShow(e, integration.id) }}>
                                                    <MagnifyingGlassIcon width={20} height={20} />
                                                    Visualizar
                                                </DropdownMenu.Item>

                                                <ToastInfo
                                                    children={
                                                        <ButtonWithAlert
                                                            callback={goToDelete}
                                                            callbackParams={integration.id}
                                                            children={
                                                                <DropdownMenu.Item>
                                                                    <TrashIcon width={20} height={20} />
                                                                    Excluir
                                                                </DropdownMenu.Item>
                                                            }
                                                        />
                                                    }
                                                    toastOpen={toastOpen}
                                                    setToastOpen={setToastOpen}
                                                    message={toastMessage}
                                                />
                                            </DropdownMenu.Content>
                                        </DropdownMenu.Root>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </Box>
            </>}

            <Pagination meta={meta} requestPerPage={fetchDatas} />
        </Container>
    );
}

export { IntegrationIndex }