import { Box, Text, Tabs } from '@radix-ui/themes';
import { TextIcon } from '@radix-ui/react-icons';
import { TextareaForm } from '../../components/form_types/textarea_form';
import { useEffect, useState } from 'react';
import { creatorInputsNewRequest } from '../../api/creator_inputs';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';

function MemoriesNew(props) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState();
    const [creatorInput, setCreatorInput] = useState({})

    const changeText = (e) => {
        e.preventDefault();
        setText(e.target.value);
    }

    const continueToNextStep = (e) => {
      setLoading(true);
      e.preventDefault();
      creatorInputsNewRequest({
        creator_identifier: user.name,
        title: creatorInput.title,
        content: text,
      }).then((response) => {
        navigate(`/memorias/${creatorInput.id}`);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }

    useEffect(() => {
      setText(props.text);
      setCreatorInput(props.creatorInput);
    }, [props]);

    return (
        <Tabs.Root defaultValue="text">
        <Tabs.List>
          <Tabs.Trigger value="text">
            <TextIcon width={20} height={20} style={{ padding: '0 calc(2px + var(--space-1))' }} />
            <Text>Texto</Text>
          </Tabs.Trigger>
        </Tabs.List>

        <Box pt="3">
          <Tabs.Content value="text">
            <TextareaForm
                name='texto'
                type='text'
                text={text}
                icon={<TextIcon width={30} height={30} />}
                changeText={changeText}
                continueToNextStep={continueToNextStep}
                loading={loading}
                continueButtonName='salvar'/>
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    )
}

export { MemoriesNew }