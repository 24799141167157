import { Flex, Text, Button, HoverCard, Box, Heading } from '@radix-ui/themes';
import { ArrowRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import * as Form from '@radix-ui/react-form';


function TextareaForm(props) {
    const { type, name, text, changeText, continueToNextStep, continueButtonName, loading = false } = props;

    const instructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="300px" style={{left: left, top: top}}>
                <Flex gap="2">
                    <InfoCircledIcon width='50' height='50' color='var(--accent-a12)' />
                    <Box>
                        <Heading size="3" as="h3">
                            Estimulos criativos
                        </Heading>
                        <Text as="div" size="2" color="gray" mb="2">
                            Coloque trechos de texto que você gostaria que a IA usasse como base para criar respostas.
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    return (
        <Form.Root className="FormRoot" onSubmit={continueToNextStep}>
            <Form.Field className="FormField" name={type}>
                <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                    <Form.Label className="FormLabel" style={{  paddingBottom: 'var(--space-2)', display: 'flex', flexDirection: 'line', alignItems: 'center', justifyContent: 'space-around' }}>
                        <Text>Insira o {name}</Text>
                    </Form.Label>

                    <Form.Message className="FormMessage" match="valueMissing">
                        Insira o {name}
                    </Form.Message>
                </Flex>

                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Form.Control asChild>
                            <textarea className="Textarea" name={type} value={text} onChange={changeText} required />
                        </Form.Control>
                    </HoverCard.Trigger>

                    {instructions({left: '850px', top: '-330px'})}
                </HoverCard.Root>
            </Form.Field>

            <Form.Submit asChild>
                <Button size="2" loading={loading}>
                    {continueButtonName}
                    <ArrowRightIcon width={20} height={20} />
                </Button>
            </Form.Submit>
        </Form.Root>
    );
}

export { TextareaForm };