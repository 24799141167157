import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Heading, Strong, Text, Flex, Table } from '@radix-ui/themes'
import { integrationShowRequest } from '../../api/integrations'
import { MarkdownText } from '../../components/markdown_text'

function IntegrationShow() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [integration, setIntegration] = useState({})

    useEffect(() => {
        const fetchIntegration = () => {
            setLoading(true)
            integrationShowRequest(id).then((response) => {
                setIntegration(response.data)
                setLoading(false)
            }).catch((error) => {
                navigate('/integracoes')
            })
        }

        fetchIntegration()
    }, [id, navigate])

    return (
        <Container size='3' p='2'>
            <Heading>Detalhe da integração</Heading>

            {loading && (<Text>Carregando...</Text>)}
            {!loading && !integration && (<Text>Não foi possível carregar o perfil</Text>)}
            {!loading && integration && (
                <Flex gap='2' mt='2' direction='column'>
                    <Strong>Tipo:</Strong>
                    <Text>{integration.type}</Text>

                    <Strong>Nome:</Strong>
                    <Text>{integration.name}</Text>

                    <Strong>Configurações:</Strong>
                    <MarkdownText text={'```json\n' + integration.configurations + '\n```\n'} />

                    <Heading size='2' mt='2'>Gatilhos</Heading>

                    <Table.Root>
                        <Table.Header>
                            <Table.Cell>Type</Table.Cell>
                            <Table.Cell>Trigger</Table.Cell>
                            <Table.Cell>Status</Table.Cell>
                        </Table.Header>
                        <Table.Body>
                            {integration.integration_triggers.data.map((trigger, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{trigger.type}</Table.Cell>
                                    <Table.Cell>{trigger.trigger}</Table.Cell>
                                    <Table.Cell>{trigger.status}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>

                    <Heading size='2' mt='2'>Configurações de Perfil</Heading>

                    <Table.Root>
                        <Table.Header>
                            <Table.Cell>Dominio</Table.Cell>
                            <Table.Cell>Perfil</Table.Cell>
                            <Table.Cell>Criador</Table.Cell>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{integration.integration_configuration.domain.name}</Table.Cell>
                                <Table.Cell>{integration.integration_configuration.profile.title}</Table.Cell>
                                <Table.Cell>{integration.integration_configuration.creator.identifier}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table.Root>
                </Flex>
            )}
        </Container>
    )
}

export { IntegrationShow }