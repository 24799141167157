import { useEffect, useState } from 'react';
import { Container, Heading, Box, DataList, Flex, Button, Strong, Text } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { Pencil2Icon } from '@radix-ui/react-icons';
import { useAuth } from '../../hooks/auth';
import { creatorsMediasByCreatorRequest } from '../../api/creators_medias';
import { INSTAGRAMDOMAINDEFAULT, TIKTOKDOMAINDEFAULT, YOUTUBEDOMAINDEFAULT } from '../../support/constants';
import { SocialIcon } from 'react-social-icons';
import { MarkdownText } from '../../components/markdown_text';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function Configurations() {
    const { user } = useAuth();
    const [medias, setMedias] = useState([]);

    useEffect(() => {
        const fetchCreatorsMedias = () => {
            creatorsMediasByCreatorRequest(user.name).then((response) => {
                const mediaUrls = response.data.data.map((data) => {
                    switch (data.media.name) {
                        case 'Instagram':
                            return INSTAGRAMDOMAINDEFAULT + data.identifier;
                        case 'TikTok':
                            return TIKTOKDOMAINDEFAULT + data.identifier;
                        case 'YouTube':
                            return YOUTUBEDOMAINDEFAULT + data.identifier;
                        default:
                            return '';
                    }
                });

                setMedias(mediaUrls);
            }).catch((error) => {
                console.error(error);
            });
        }

        fetchCreatorsMedias();
    }, [user]);

    return (
        <Container size='3' p='2'>
            <Flex gap='2' my='4'>
                <Heading>Meus dados</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/configuracoes/editar'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Editar meus dados
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <Pencil2Icon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            <Box display={displayOnlyLaptopsAndDesktops()}>
                <DataList.Root>
                    <DataList.Item>
                        <DataList.Label>Nome</DataList.Label>
                        <DataList.Value>{user.name}</DataList.Value>
                    </DataList.Item>

                    <DataList.Item>
                        <DataList.Label>Email</DataList.Label>
                        <DataList.Value>{user.email}</DataList.Value>
                    </DataList.Item>

                    <DataList.Item>
                        <DataList.Label>Telefone</DataList.Label>
                        <DataList.Value>{user.cellphone}</DataList.Value>
                    </DataList.Item>

                    <DataList.Item>
                        <DataList.Label>Descrição</DataList.Label>
                        <DataList.Value>{user.description}</DataList.Value>
                    </DataList.Item>

                    <DataList.Item>
                        <DataList.Label>Redes Sociais</DataList.Label>
                        <DataList.Value>
                            <Flex gap='2'>
                                {medias.length > 0 && medias.map((media, index) => (
                                    <SocialIcon key={index} url={media} style={{ width: 'var(--space-6)', height: 'var(--space-6)' }} />
                                ))}
                            </Flex>
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>
            </Box>

            <Box display={displayOnlyMobiles()}>
                <Flex gap='2' direction='column'>
                    <Strong>Nome</Strong>
                    <Text>{user.name}</Text>

                    <Strong>Email</Strong>
                    <Text>{user.email}</Text>

                    {user.cellphone && <>
                        <Strong>Telefone</Strong>
                        <Text>{user.cellphone}</Text>
                    </>}

                    {user.description && <>
                        <Strong>Descrição</Strong>
                        <MarkdownText text={user.description} />
                    </>}

                    <Flex gap='2' align='center'>
                        <Strong>Redes Sociais:</Strong>
                        {medias.length > 0 && medias.map((media, index) => (
                            <SocialIcon key={index} url={media} style={{ width: 'var(--space-6)', height: 'var(--space-6)' }} />
                        ))}
                    </Flex>
                </Flex>
            </Box>
        </Container>
    );
}

export { Configurations }