import React, { useState } from 'react';
import { Container, Heading } from '@radix-ui/themes';
import { IntegrationForm } from './form';
import { useAuth } from '../../hooks/auth';
import { integrationCreateRequest } from '../../api/integrations';
import { useNavigate } from 'react-router-dom';

function IntegrationNew() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const defaultConfigurations = {
        webhook: {
            url: 'https://sua_url.com/path',
            headers: [
                { key: 'Authorization', value: 'token'}
            ],
            method: 'GET'
        },
        websocket: {
            url: 'https://sua_url.com/path'
        },
        whatsapp: {
            channel_id: 'xpto123',
            token: 'token123',
            triggers: [
                {
                    'type': 'start',
                    'trigger': '*'
                },
                {
                    'type': 'stop',
                    'trigger': 'não sei'
                }
            ],
            creator_identifier: '',
            domain_name: '',
            profile_title: ''
        },
        telegram: {
            phone: '+5511999999999'
        }
    }

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentType, setCurrentType] = useState('webhook');
    const [formData, setFormData] = useState({
        type: 'webhook',
        configurations: JSON.stringify(defaultConfigurations['webhook']),
        needs_moderation: true,
        account_id: user.id
    });

    const handleType = (name) => {
        const configurations = JSON.stringify(defaultConfigurations[name]);
        setCurrentType(name);
        setFormData({ ...formData, type: name, configurations: configurations});
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors([]);

        integrationCreateRequest(formData).then((response) => {
            setLoading(false);
            navigate('/integracoes');
        }).catch((error) => {
            setLoading(false);
            setErrors(error.response.data.errors);
        });
    }

    return (
        <Container size='3' p='2'>
            <Heading>Inserir nova integração</Heading>
            <IntegrationForm
                errors={errors}
                formData={formData}
                currentType={currentType}
                setFormData={setFormData}
                handleChange={handleChange}
                handleType={handleType}
                handleSubmit={handleSubmit}
                loading={loading}/>
        </Container>
    );
}

export { IntegrationNew }