import { api, headers } from './base';

const accountsDomainRequest = async (formData) => {
    return await api.post('/accounts_domains', formData, headers());
}

const accountsDomainAllRequest = async (account_id, page = 1, per_page = 10) => {
    return await api.get(`/accounts_domains/${account_id}?page=${page}&per_page=${per_page}`, headers())
}

const accountsDomainPrivatesRequest = async (account_id, page = 1, per_page = 10) => {
    return await api.get(`/accounts_domains/${account_id}/privates?page=${page}&per_page=${per_page}`, headers())
}

const accountByAccountRequest = async (account_domain_id, page = 1, per_page = 10) => {
    return await api.get(`/accounts_domains/by_account/${account_domain_id}?page=${page}&per_page=${per_page}`, headers());
}

const accountsDomainUpdateRequest = async (account_domain_id, formData) => {
    return await api.put(`/accounts_domains/${account_domain_id}`, formData, headers());
}

const accountsDomainDeleteRequest = async (account_domain_id) => {
    return await api.delete(`/accounts_domains/${account_domain_id}`, headers());
}

export { accountsDomainRequest, accountsDomainPrivatesRequest, accountsDomainAllRequest, accountsDomainDeleteRequest, accountsDomainUpdateRequest, accountByAccountRequest }