
import { useState, useEffect } from 'react';
import { Box, Text, Flex, Button, Callout, HoverCard, Heading } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { ArrowRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { creatorsNewRequest } from '../../../api/creators';
import { accountsDomainRequest } from '../../../api/accounts_domains';

function DomainStep(props) {
    const { completeStep, user, updateSession } = props;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        creatorsNewRequest({identifier: user.name}).catch((_) => {
            console.log('Erro ao criar criador');
        });
    }, [user.name]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        accountsDomainRequest({ domain_name: e.target['domain_name'].value, account_id: user.id }).then(() => {
            setErrors([]);

            user.has_domain = true;
            updateSession(user)

            setLoading(false);
            completeStep(1)
        }).catch((_) => {
            setErrors(['Erro ao criar domínio. Tente novamente.']);
            setLoading(false);
        });
    }

    const instructions = (styles) => {
        const {left, top} = styles;

        return (
            <HoverCard.Content maxWidth="300px" style={{left: left, top: top}}>
                <Flex gap="4">
                    <Box>
                        <Heading size="3" as="h3">
                            O que é um domínio?
                        </Heading>
                        <Text as="div" size="2" color="gray" mb="2">
                            Função que a inteligência artificial irá desempenhar.
                        </Text>
                        <Text as="div" size="2">
                            Qual é a função que você gostaria que
                            a inteligência artificial fizesse por você?
                        </Text>
                    </Box>
                </Flex>
            </HoverCard.Content>
        )
    }

    return (
        <Box mt='2'>
            {errors.length > 0 && (
                <Callout.Root mb='4' color="red">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        {errors.map((error, index) => (
                            <Text key={index}>{error}</Text>
                        ))}
                    </Callout.Text>
                </Callout.Root>
            )}

            <Flex direction='column' gap='2'>
                <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                    <Form.Field className="FormField" name='domain_name' >
                        <Flex align="baseline" direction='column' style={{ justifyContent: 'space-between' }}>
                            <Form.Label className='FormLabel'>
                                <Flex align="center" gap="2" mb='2' mt='4'>
                                    <Text>Vamos definir o domínio da sua IA.</Text>
                                    <HoverCard.Root>
                                        <HoverCard.Trigger>
                                            <InfoCircledIcon width='20' height='20' />
                                        </HoverCard.Trigger>

                                        {instructions({left: '25px', top: '-30px'})}
                                    </HoverCard.Root>
                                </Flex>
                            </Form.Label>
                            <Form.Message className='FormMessage' match="valueMissing">
                                Insira o nome de um domínio
                            </Form.Message>
                        </Flex>

                        <Flex align="center" style={{ justifyContent: 'space-between' }}>
                            <HoverCard.Root>
                                <HoverCard.Trigger>
                                    <Form.Control disabled={loading} asChild>
                                        <input className="Input" type='text' name='domain_name' required style={{ width: '100%' }} autoComplete='off' />
                                    </Form.Control>
                                </HoverCard.Trigger>

                                {instructions({left: '860px', top: '-50px'})}
                            </HoverCard.Root>
                        </Flex>
                    </Form.Field>

                    <Form.Submit asChild>
                        <Button size="2" type="submit" mb='4' mt='2' loading={loading} >
                            Avançar
                            <ArrowRightIcon width={20} height={20} />
                        </Button>
                    </Form.Submit>
                </Form.Root>
            </Flex>
        </Box>
    );
}

export { DomainStep }