import { ArrowRightIcon, TrashIcon, PlusIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { Box, Container, Flex, Heading, Text, Button, IconButton, Callout } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { useRef, useState } from 'react';
import { accountsDomainRequest } from '../../api/accounts_domains';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';

function DomainsNew(props) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const formRef = useRef();
    const [textInputs, setTextInputs] = useState([0]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const deleteTextInput = (e, index) => {
        e.preventDefault();

        if (textInputs.length === 1) {
            return;
        }

        setTextInputs(textInputs.filter((i) => i !== index));
    }

    const addTextInput = (e) => {
        e.preventDefault();
        var maxTextInputValue = textInputs.length === 0 ? 0 : Math.max(...textInputs);
        setTextInputs([...textInputs, ++maxTextInputValue]);
    };

    var handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const domains = textInputs.map((_, i) => e.target[`domain[${i}]`].value);

        domains.forEach((domain) => {
            accountsDomainRequest({
                domain_name: domain,
                account_id: user.id
            }).then((response) => {
                setErrors([]);
                setLoading(false);
                if (props.redirectToSuccess) {
                    props.redirectToSuccess();
                    return;
                } else {
                    navigate('/dominios/sucesso');
                }
            }).catch((error) => {
                setErrors([error.response.data.message]);
                setLoading(false);
            });
        });
    }

    const BuildTextInput = (index) => (
        <Form.Field className="FormField" key={index} name={`domain[${index}]`} >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o nome de um domínio
                </Form.Message>
            </Flex>

            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                <Form.Control disabled={loading} asChild>
                    <input className="Input" type='text' name={`domain[${index}]`} required style={{ width: '100%' }} />
                </Form.Control>

                <IconButton size="1" ml='6' color='red' name={`delete-domain[${index}]`} onClick={(e) => deleteTextInput(e, index)} >
                    <TrashIcon width={20} height={20} />
                </IconButton>
            </Flex>
        </Form.Field>
    )

    return (
        <Container size='3' mt='2' px='2'>
            <Heading>Adição de domínios</Heading>

            <Box>
                {errors.length > 0 && (
                    <Callout.Root mb='4' color="red">
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text>
                            {errors.map((error, index) => (
                                <Text key={index}>{error}</Text>
                            ))}
                        </Callout.Text>
                    </Callout.Root>
                )}

                <Flex direction='column' gap='3'>
                    <Text>Vamos definir seus domínios.</Text>

                    <Button size="2" mt='2' onClick={addTextInput} >
                        <Text>Adicionar domínio</Text>
                        <PlusIcon width={20} height={20} />
                    </Button>

                    <Form.Root className="FormRoot" ref={formRef} onSubmit={handleSubmit} >
                        {textInputs.map(BuildTextInput)}

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={loading} >
                                Avançar
                                <ArrowRightIcon width={20} height={20} />
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Flex>
            </Box>
        </Container>
    );
}

export { DomainsNew }