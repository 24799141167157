import { api, headers } from './base';

const profileShowByCreatorNameRequest = async (creatorName, page, perPage = 10) => {
    return await api.get(`/profile/creator/${creatorName}?page=${page}&per_page=${perPage}`, headers());
}

const profileShowDomainRequest = async (domainName, page, perPage = 10) => {
    return await api.get(`/profile/domain/${domainName}?page=${page}&per_page=${perPage}`, headers());
}

const profileShowRequest = async (id) => {
    return await api.get(`/profile/${id}`, headers());
}

const profilesCreateRequest = async (formData) => {
    return await api.post('/profile', formData, headers());
}

const profilesUpdateRequest = async (id, formData) => {
    return await api.put(`/profile/${id}`, formData, headers());
}

export { profileShowByCreatorNameRequest, profilesCreateRequest, profileShowRequest, profilesUpdateRequest, profileShowDomainRequest };