import { useState, useRef } from 'react';
import { Link1Icon, PlusIcon, TrashIcon, ArrowRightIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import * as Form from '@radix-ui/react-form';
import { Flex, Button, IconButton, Text, Callout } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom'

function LinksForm(props) {
    const navigate = useNavigate();
    const textFormRef = useRef();
    const [textInputs, setTextInputs] = useState([0]);

    const BuildTextInput = (index) => (
        <Form.Field className="FormField" key={index} ref={textFormRef} >
            <Form.Message className="FormMessage" match="valueMissing">
                Insira um link
            </Form.Message>

            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                <Form.Control asChild>
                    <input className="Input" type='link' name={`${props.type}[${index}]`} required style={{ width: '100%' }} />
                </Form.Control>

                <IconButton size="1" ml='6' color='red' onClick={() => deleteTextInput(index)} >
                    <TrashIcon width={20} height={20} />
                </IconButton>
            </Flex>
        </Form.Field>
    )

    const deleteTextInput = (index) => {
        if (textInputs.length === 1) {
            return;
        }

        setTextInputs(textInputs.filter((i) => i !== index));
    }

    const addTextInput = () => {
        var maxTexts = Math.max(...textInputs);
        setTextInputs([...textInputs, ++maxTexts]);
    }


    const continueToNextStep = (e) => {
        e.preventDefault();
        const links = textInputs.map((_, i) => e.target[`${props.type}[${i}]`].value);
        navigate('/memorias/nova/categorizar', { state: { files: [], text: null, links: links } });
    }

    return (
        <>
            <Callout.Root style={{alignItems: 'center'}}>
                <Callout.Icon>
                    <InfoCircledIcon />
                </Callout.Icon>
                <Callout.Text>
                    São aceitos links: TikTok, Instagram, YouTube e páginas públicas da web.
                </Callout.Text>
            </Callout.Root>

            <Flex gap="2" align="center" pb='3' mt='4' style={{ justifyContent: 'space-between' }} >
                <Flex gap="2" align="center">
                    <Link1Icon width={20} height={20} />
                    <Text>Insira o {props.name}</Text>
                </Flex>

                <IconButton size="1" onClick={addTextInput} >
                    <PlusIcon width={20} height={20} />
                </IconButton>
            </Flex>
            <Form.Root className="FormRoot" onSubmit={continueToNextStep}>
                {textInputs.map(BuildTextInput)}

                <Form.Submit className='FormSubmit' asChild>
                    <Button size="2" type='submit'>
                        Avançar
                        <ArrowRightIcon width={20} height={20} />
                    </Button>
                </Form.Submit>
            </Form.Root>
        </>
    )
}

export { LinksForm };