import { ContentViewsForm } from './form';
import { Container, Heading } from '@radix-ui/themes';

function CreatorInputsNew() {
    return (
        <Container size='3' p='2'>
            <Heading>Seleção de memórias</Heading>
            <ContentViewsForm tabs={['text', 'documents', 'powerpoint', 'video', 'audio', 'link']}/>
        </Container>
    );
}

export { CreatorInputsNew };