import React, { useState, useEffect } from 'react';
import { Container, Heading } from '@radix-ui/themes';
import { ProfileForm } from './form';
import { useNavigate, useParams } from 'react-router-dom';
import { profileShowRequest, profilesUpdateRequest } from '../../api/profiles';
import { accountsDomainAllRequest } from '../../api/accounts_domains';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/loading';

function ProfileEdit() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { id } = useParams();
    const [formData, setFormData] = useState({});
    const [skills, setSkills] = useState({});
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [accountsDomains, setAccountsDomains] = useState([]);
    const [skillCounts, setSkillCounts] = useState([0]);
    const [errors, setErrors] = useState([]);
    const [serverErrors, setServerErrors] = useState([]);

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    var handleDomainChange = (value) => {
        setServerErrors({ domain_name: false });
        setFormData({
            ...formData,
            domain_name: value
        });
    }

    var handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);

        var body = {
            ...formData,
            skills: Object.values(skills)
        };
        profilesUpdateRequest(profile.id, body).then((response) => {
            setLoadingSubmit(false);
            navigate('/perfis')
        }).catch((error) => {
            setErrors([error.response.data.error]);
            setLoadingSubmit(false);
        });
    }

    useEffect(() => {
        const fetchProfile = () => {
            setLoading(true);
            profileShowRequest(id).then((response) => {
                setProfile(response.data);
                setFormData({
                    title: response.data.title,
                    text: response.data.text,
                    allow_searching_entire_web: response.data.allow_searching_entire_web,
                    domain_name: response.data.domain.name,
                });

                var handleSkills = {}
                response.data.skills.forEach((skill, index) => {
                    handleSkills[`skills[${index}]`] = skill.text;
                });

                setSkills(handleSkills)
                setSkillCounts(Array.from({ length: response.data.skills.length }, (_, i) => i));
                setLoading(false);
            });
        }

        var fetchDomains = () => {
            accountsDomainAllRequest(user.id, 1, 999).then((response) => {
                setAccountsDomains(response.data.data);
            })
        }

        fetchProfile();
        fetchDomains();
    }, [id, user]);

    return (
        <Container size='3' p='2'>
            <Heading>Editar perfil</Heading>
            {loading && <Loading />}
            {!loading && <ProfileForm
                errors={errors}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                handleDomainChange={handleDomainChange}
                formData={formData}
                accountsDomains={accountsDomains}
                skills={skills}
                setSkills={setSkills}
                skillCounts={skillCounts}
                setSkillCounts={setSkillCounts}
                serverErrors={serverErrors}
                setServerErrors={setServerErrors}
                loading={loadingSubmit} />}
        </Container>
    );
}

export { ProfileEdit }