import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Strong, Text, Button } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { Moderation } from '../components/moderation';
import { useAuth } from '../../../hooks/auth';
import { integrationEventsToModerationShowRequest } from '../../../api/integration_events';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Loading } from '../../../components/loading';
import { EmptyList } from '../../../components/empty_list';

function EventsModerationShow() {
    const { id } = useParams();
    const { user } = useAuth();
    const [event, setEvent] = useState({});
    const [answers, setAnswers] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchIntegrationEventToModerate = useCallback(() => {
        integrationEventsToModerationShowRequest(user.id, id).then(response => {
            if (response.data.data.length > 0) {
                setEvent(response.data.data[0].payload.messages[0]);
                setAnswers(response.data.data);
            }

            setLoading(false);
        }).catch(error => {
            setLoading(false);
        });
    }, [user.id, id]);

    useEffect(() => {
        fetchIntegrationEventToModerate();
    }, [fetchIntegrationEventToModerate]);

    return (
        <>
            {loading && <Loading />}
            {!loading && Object.keys(event).length === 0 && <EmptyList />}

            <Flex direction='column' gap='1' p='2' maxWidth='920px' style={{margin: '0 auto'}}>
                {!loading && Object.keys(event).length !== 0 && <Flex direction='column' gap='4'>
                    <Strong>#{id}</Strong>
                    <Flex gap='1'>
                        <Strong>Número:</Strong>
                        <Text>{event.from}</Text>
                    </Flex>

                    <Flex gap='1'>
                        <Strong>Nome:</Strong>
                        <Text>{event.chat_name || event.from_name}</Text>
                    </Flex>

                    <Flex gap='1'>
                        <Strong>Data</Strong>
                        <Text>{new Date(event.timestamp * 1000).toLocaleDateString('pt-BR')}</Text>
                    </Flex>

                    <Moderation answers={answers} />
                </Flex>}

                <Button as='a' href='/moderacoes' mt='4' asChild>
                    <Link to={`/eventos-recebidos/${event.from}`}>
                        <ArrowLeftIcon />
                        Voltar pra lista
                    </Link>
                </Button>
            </Flex>
        </>
    );
}

export { EventsModerationShow };