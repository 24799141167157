import { Box, Button, Card, Container, Flex, Heading, Text } from '@radix-ui/themes'
import { ArrowRightIcon, CheckIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

function DomainsSucess() {
    const navigate = useNavigate();

    const goToIndex = (e) => {
        e.preventDefault();
        navigate('/dominios');
    }

    return (
        <Container mt='6' size='3'>
            <Heading>
                <Flex align='center' gap='2'>
                    <CheckIcon width='50' height='50' color='green' />
                    <Text>Sucesso!</Text>
                </Flex>
            </Heading>

            <Card mt='6'>
                <Box p='4'>
                    <Flex direction='column' gap='2'>
                        <Text>Seu domínio foi feito com sucesso!</Text>
                    </Flex>
                </Box>

                <Box p='4'>
                    <Button onClick={goToIndex} >
                        Continuar
                        < ArrowRightIcon />
                    </Button>
                </Box>
            </Card>
        </Container>
    )
}

export { DomainsSucess }
