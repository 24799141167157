import { InfoCircledIcon, Link1Icon, ChatBubbleIcon, TimerIcon } from '@radix-ui/react-icons';
import { Box, Callout, Text, Flex, Select, Button } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';

import { IntegrationFormWebHook } from './types/webhook';
import { IntegrationFormWebSocket } from './types/websocket';
import { IntegrationFormWhatsApp } from './types/whatsapp';
import { IntegrationFormTelegram } from './types/telegram';

function IntegrationForm(props) {
    const { formData, setFormData, errors, loading, handleSubmit, handleChange, handleType, currentType } = props;

    return (
        <Box>
            {errors.length > 0 && (
                <Callout.Root mb='4' color="red">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        {errors.map((error, index) => (
                            <Text key={index}>{error}</Text>
                        ))}
                    </Callout.Text>
                </Callout.Root>
            )}

            <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                <Form.Field className="FormField" name="type" >
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Tipo</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira o tipo
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild>
                        <Select.Root defaultValue='webhook' value={formData?.type} onValueChange={handleType}>
                            <Select.Trigger />
                            <Select.Content>
                               <Select.Group>
                                    <Select.Label>Selecione o tipo</Select.Label>
                                    <Select.Item value="webhook">
                                        <Flex gap='2' align='center'>
                                            <Link1Icon />
                                            WebHook
                                        </Flex>
                                    </Select.Item>

                                    <Select.Item value="whatsapp">
                                        <Flex gap='2' align='center'>
                                            <ChatBubbleIcon />
                                            WhatsApp
                                        </Flex>
                                    </Select.Item>

                                    <Select.Item value="websocket" disabled>
                                        <Flex gap='2' align='center'>
                                            <TimerIcon />
                                            WebSocket (em breve)
                                        </Flex>
                                    </Select.Item>

                                    <Select.Item value="telegram" disabled>
                                        <Flex gap='2' align='center'>
                                            <ChatBubbleIcon />
                                            Telegram (em breve)
                                        </Flex>
                                    </Select.Item>
                                </Select.Group>
                            </Select.Content>
                        </Select.Root>
                    </Form.Control>
                </Form.Field>

                <Form.Field className="FormField" name="name" >
                    <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                        <Form.Label className='FormLabel'>Nome</Form.Label>

                        <Form.Message className='FormMessage' match="valueMissing">
                            Insira o nome
                        </Form.Message>
                    </Flex>

                    <Form.Control asChild>
                        <input className="Input" type="text" name="name" value={formData?.name} onChange={handleChange} required />
                    </Form.Control>
                </Form.Field>

                {formData?.type === 'webhook' && <IntegrationFormWebHook formData={formData} setFormData={setFormData} />}
                {formData?.type === 'whatsapp' && <IntegrationFormWhatsApp formData={formData} setFormData={setFormData} currentType={currentType} />}
                {formData?.type === 'websocket' && <IntegrationFormWebSocket formData={formData} setFormData={setFormData} />}
                {formData?.type === 'telegram' && <IntegrationFormTelegram formData={formData} setFormData={setFormData} />}

                <Form.Submit asChild>
                    <Button size="2" type="submit" loading={loading}>
                        Salvar
                    </Button>
                </Form.Submit>
            </Form.Root>
        </Box>
    )
}

export { IntegrationForm };