import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/auth';
import { Box, Button, Container, Heading, Text, Flex } from '@radix-ui/themes';
import { ArrowRightIcon, CheckIcon } from '@radix-ui/react-icons';

function RegisterSucess() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const location = useLocation();
    const [state] = useState(location.state ? location.state : {});

    var goToLogin = async (e) => {
        e.preventDefault();
        login(state.body);
        navigate('/welcome')
    }

    return (
        <Container size='1' mt='6'>
            <Heading>
                <Flex align='center' gap='2'>
                    <CheckIcon width='50' height='50' color='green' />
                    <Text>Sucesso!</Text>
                </Flex>
            </Heading>

            <Flex direction='column'>
                <Box p='4'>
                    <Flex direction='column' gap='2'>
                        <Text>Cadastro feito com sucesso!</Text>
                        <Text>Vamos começar?</Text>
                    </Flex>
                </Box>

                <Box p='4'>
                    <Button onClick={goToLogin}>
                        Começar
                        < ArrowRightIcon />
                    </Button>
                </Box>
            </Flex>
        </Container>
    );
}

export { RegisterSucess }