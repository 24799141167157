import { api, headers } from './base';

const integrationIndexRequest = async (accountId, page, perPage) => {
    return await api.get(`/integrations?account_id=${accountId}&page=${page}&per_page=${perPage}`, headers());
}

const integrationShowRequest = async (id) => {
    return await api.get(`/integrations/${id}`, headers());
}

const integrationCreateRequest = async (formData) => {
    return await api.post('/integrations', formData, headers());
}

const integrationUpdateRequest = async (id, formData) => {
    return await api.put(`/integrations/${id}`, formData, headers());
}

const integrationDeleteRequest = async (id) => {
    return await api.delete(`/integrations/${id}`, headers());
}

export {
    integrationIndexRequest,
    integrationShowRequest,
    integrationCreateRequest,
    integrationUpdateRequest,
    integrationDeleteRequest
};