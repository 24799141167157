import { api, headers } from './base';

const scheduleIntegrationIndexRequest = async (accountId, page, perPage) => {
    return await api.get(`/schedule_integrations?account_id=${accountId}&page=${page}&per_page=${perPage}`, headers());
}

const scheduleIntegrationShowRequest = async (id) => {
    return await api.get(`/schedule_integrations/${id}`, headers());
}

const scheduleIntegrationCreateRequest = async (formData) => {
    return await api.post('/schedule_integrations', formData, headers());
}

const scheduleIntegrationUpdateRequest = async (id, formData) => {
    return await api.put(`/schedule_integrations/${id}`, formData, headers());
}

const scheduleIntegrationDeleteRequest = async (id) => {
    return await api.delete(`/schedule_integrations/${id}`, headers());
}

const scheduleIntegrationActivateRequest = async (id) => {
    return await api.put(`/schedule_integrations/${id}/activate`, {}, headers());
}

const scheduleIntegrationDeactivateRequest = async (id) => {
    return await api.put(`/schedule_integrations/${id}/deactivate`, {}, headers());
}

export {
    scheduleIntegrationIndexRequest,
    scheduleIntegrationShowRequest,
    scheduleIntegrationCreateRequest,
    scheduleIntegrationUpdateRequest,
    scheduleIntegrationDeleteRequest,
    scheduleIntegrationActivateRequest,
    scheduleIntegrationDeactivateRequest
};