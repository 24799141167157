import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import remarkRehypers from 'remark-rehype'
import remarkFrontmatter from 'remark-frontmatter'
import remarkMath from 'remark-math'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { segmentShowRequest } from '../api/segments'
import ReactPlayer from 'react-player'
import { Flex, Heading } from '@radix-ui/themes'

function MarkdownText(props) {
    const { text, system } = props;
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoConfig, setVideoConfig] = useState(null);

    useEffect(() => {
        if (system && text) {
            const segmentId = Number(text.match(/\d+/)?.[0])
            segmentShowRequest(segmentId).then((segment) => {
                let segmentAttrs = segment.data
                if (segmentAttrs.link_extraction.type !== 'video') {
                    return
                }

                setVideoUrl(segmentAttrs.link_extraction.url)

                let options = {}
                if (segmentAttrs.link_extraction.origin === 'youtube') {
                    const startAt = ((segment.data.current_segment + 1) * 60) + segment.data.start
                    options = {
                        youtube: {
                            playerVars: { start: Math.ceil(startAt), controls: 1 }
                        }
                    }
                }
                setVideoConfig(options)
            }).catch((error) => {
                console.log(error)
            });
        }
    }, [system, text]);

    const evidenceVideo = () => {
        return (
            (videoUrl && videoConfig) ? (
                <Flex direction='column'>
                    <Heading size='2' mb='3'>Veja a fonte que o Withub se baseou:</Heading>
                    {markDownVideo()}
                </Flex>
            ) : null
        )
    }

    const markDownVideo = () => {
        return (<ReactPlayer
            url={videoUrl}
            config={videoConfig}
            width='100%'
        />);
    };

    const markDownText = (text) => {
        return (
            <Markdown
                width='100%'
                remarkPlugins={[
                    remarkGfm,
                    remarkBreaks,
                    remarkRehypers,
                    remarkFrontmatter,
                    remarkMath
                ]}
                children={text}
                components={{
                    code(props) {
                        const {children, className, node, ...rest} = props
                        const match = /language-(\w+)/.exec(className || '')
                        return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            wrapLines={true}
                            wrapLongLines={true}
                            style={dark}
                            showInlineLineNumbers={true}
                        />
                        ) : (
                        <code className={className} {...rest}>
                            {children}
                        </code>
                        )
                    }
                }}
            />
        )
    }

    return (
        !system ? markDownText(text) : evidenceVideo(text)
    )
}

export { MarkdownText }