import { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Container, Heading, Box, Flex, Button, Link as LinkUI, Separator, Callout, Text } from '@radix-ui/themes';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import * as Form from '@radix-ui/react-form';
import { encryptWithPublicKey } from '../../support/cryptography';
import { resetPasswordRequest } from '../../api/accounts';

function ResetPasswordNew() {
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [formData, setFormData] = useState({
        token: new URLSearchParams(window.location.search).get('token'),
    });

    var handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            iat: new Date().toISOString()
        });
    }

    var handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);

        setFormData({
            ...formData,
            iat: new Date().toISOString()
        });

        encryptWithPublicKey(formData).then((data) => {
            resetPasswordRequest({
                hash: data
            }).then((response) => {
                setIsLoading(false);
                setSuccess(true);
            }).catch((error) => {
                setIsLoading(false);
                setErrors([error.response.data.error])
            });
        });
    }

    return (
        <Container size='4' maxWidth='920px' p='2' align='center'>
            {success && <Navigate to="/login" replace={true} />}
            <Heading>Trocar a senha</Heading>

            <Flex direction='column'>
                <Box>
                    {errors.length > 0 && (
                        <Callout.Root mb='4' color="red">
                            <Callout.Icon>
                                <InfoCircledIcon />
                            </Callout.Icon>
                            <Callout.Text>
                                {errors.map((error, index) => (
                                    <Text key={index}>{error}</Text>
                                ))}
                            </Callout.Text>
                        </Callout.Root>
                    )}
                    <Form.Root className="FormRoot" onSubmit={handleSubmit} >
                        <Form.Field className="FormField" name="password" >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Senha</Form.Label>

                                <Form.Message className='FormMessage' match={(password, formData) => password.length < 8 || !password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)}>
                                    Deve conter letras, números e 8 caracteres
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="password" name="password" onChange={handleChange} required />
                            </Form.Control>
                        </Form.Field>


                        <Form.Field className="FormField" name="confirm_password">
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Confirme a Senha</Form.Label>

                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira a senha
                                </Form.Message>

                                <Form.Message className='FormMessage' match={(value, formData) => value !== formData.get('password')}>
                                    As senhas não conferem
                                </Form.Message>
                            </Flex>

                            <Form.Control asChild>
                                <input className="Input" type="password" name="confirm_password" required />
                            </Form.Control>
                        </Form.Field>

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={isLoading} >
                                Salvar
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Box>

                <Flex justify='start' mt='4' gap='4' style={{alignItems: 'center'}}>
                    <Box>
                        <LinkUI asChild>
                            <Link to='/cadastro'>Criar conta</Link>
                        </LinkUI>
                    </Box>
                    <Separator orientation='vertical' size='1' />
                    <Box pl='2'>
                        <LinkUI asChild>
                            <Link to='/login'>Login</Link>
                        </LinkUI>
                    </Box>
                </Flex>
            </Flex>
        </Container>
    );
}

export { ResetPasswordNew }