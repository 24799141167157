import { api, headers } from './base';

const domainShowRequest = async (name) => {
    return await api.get(`/domain/${name}`, headers());
}

const domainUpdateRequest = async (name, formData) => {
    return await api.put(`/domain/${name}`, formData, headers());
}

export { domainShowRequest, domainUpdateRequest };