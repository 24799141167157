import { api, headers } from './base';

const integrationEventBlacklistsCreateRequest = async (attributes) => {
    return await api.post(`/integration_event_blacklists`, attributes, headers());
}

const integrationEventBlacklistsDeleteRequest = async (attributes) => {
    return await api.delete(`/integration_event_blacklists?account_id=${attributes.account_id}&integration_id=${attributes.integration_id}&identifier=${attributes.identifier}`, headers());
}

export { integrationEventBlacklistsCreateRequest, integrationEventBlacklistsDeleteRequest };