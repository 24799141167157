import { api, headers } from './base';

const linkExtractionCreateRequest = async (formData) => {
    return await api.post('/link_extraction', formData, headers({'Content-Type': 'multipart/form-data'}));
}

const linkExtractionAccountRequest = async (id, page, perPage=10) => {
    return await api.get(`/link_extraction/account/${id}?page=${page}&per_page=${perPage}`, headers());
}

const linkExtractionPendingRequest = async (id) => {
    return await api.get(`/link_extraction/account/${id}/status/pending`, headers());
}

const linkExtractionDeleteRequest = async (id) => {
    return await api.delete(`/link_extraction/${id}`, headers());
}

export { linkExtractionCreateRequest, linkExtractionAccountRequest, linkExtractionPendingRequest, linkExtractionDeleteRequest }