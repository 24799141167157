import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Heading } from "@radix-ui/themes";
import { IntegrationForm } from "./form";
import { integrationShowRequest, integrationUpdateRequest } from "../../api/integrations";

function IntegrationEdit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({});
    const [currentType, setCurrentType] = useState('webhook');
    const defaultConfigurations = {
        webhook: {
            url: 'http://localhost:3000/foo/bar',
            headers: [
                { key: 'Authorization', value: '123'}
            ],
            method: 'GET'
        },
        whatsapp: {
            channel_id: 'xpto123',
            token: 'token123',
            triggers: [
                {
                    'type': 'start',
                    'trigger': '*'
                },
                {
                    'type': 'stop',
                    'trigger': 'não sei'
                }
            ],
            creator_identifier: '',
            domain_name: '',
            profile_title: ''
        },
        websocket: {
            url: ''
        },
        telegram: {
            phone: ''
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleType = (name) => {
        const configurations = JSON.stringify(defaultConfigurations[name]);
        setFormData({ ...formData, type: name, configurations: configurations});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setErrors([]);

        integrationUpdateRequest(id, formData).then((response) => {
            setLoading(false);
            navigate('/integracoes');
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            setErrors(error.response.data.errors);
        });
    }

    useEffect(() => {
        const fetchIntegration = async () => {
            integrationShowRequest(id).then((response) => {
                setLoading(false);
                setFormData(response.data);
                setCurrentType(response.data.type);
            }).catch((error) => {
                console.error(error);
            });
        }

        fetchIntegration();
    }, [id]);

    return (
        <Container size='3' p='2'>
            <Heading>Editar essa integração</Heading>
            <IntegrationForm
                errors={errors}
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                handleType={handleType}
                handleSubmit={handleSubmit}
                currentType={currentType}
                loading={loading}/>
        </Container>
    );
}

export { IntegrationEdit }