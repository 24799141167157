import { api, headers } from './base';

const contentViewResponseShowRequest = async (id) => {
    return await api.get(`/content_view_response/${id}`, headers());
}

const contentViewResponsesByCreatorInputRequest = async (id, page, perPage) => {
    return await api.get(`/content_view_response/by_creator_input/${id}?page=${page}&per_page=${perPage}`, headers());
}

export { contentViewResponseShowRequest, contentViewResponsesByCreatorInputRequest }