import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Callout, Container, Flex, Heading, Text, Strong } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { ArrowRightIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { domainShowRequest, domainUpdateRequest } from '../../api/domains';

function DomainsEdit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [formData, setFormData] = useState({});

    const updateField = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        domainUpdateRequest(formData.name, formData).then((response) => {
            navigate('/dominios');
        }).catch((error) => {
            setErrors([error.response.data.error]);
        })

        setLoading(false);
    }

    useEffect(() => {
        const fetchDomain = async () => {
            domainShowRequest(id).then((response) => {
                setFormData({
                    name: response.data.name,
                    domain_name: response.data.name,
                    head_limit: response.data.head_limit,
                    threshold_similarity: response.data.threshold_similarity
                });
            }).catch((error) => {
                console.error(error);
            });
        }

        fetchDomain();
    }, [id]);

    return (
        <Container size='3' mt='2' px='2'>
            <Heading>Edição de domínio</Heading>

            <Box>
                {errors.length > 0 && (
                    <Callout.Root mb='4' color="red">
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text>
                            {errors.map((error, index) => (
                                <Text key={index}>{error}</Text>
                            ))}
                        </Callout.Text>
                    </Callout.Root>
                )}

                <Flex direction='column' gap='2'>
                    <Form.Root className="FormRoot" ref={formRef} onSubmit={handleSubmit} >
                        <Form.Field className="FormField" name='domain_name' >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Nome do domínio</Form.Label>
                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira o nome de um domínio
                                </Form.Message>
                            </Flex>

                            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                                <Form.Control disabled={loading} asChild>
                                    <input className="Input" onChange={updateField} value={formData.domain_name} type='text' name='domain_name' required style={{ width: '100%' }} />
                                </Form.Control>
                            </Flex>
                        </Form.Field>

                        <Form.Field className="FormField" name='head_limit' >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>
                                    Limite de memórias por resposta
                                </Form.Label>
                                <Text>
                                    <Strong>
                                        {formData.head_limit}
                                    </Strong>
                                </Text>
                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira um valor
                                </Form.Message>
                            </Flex>

                            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                                <Form.Control disabled={loading} asChild>
                                    <input className="Input"  onChange={updateField} value={formData.head_limit} min="1" max="100" type='range' name='head_limit' required style={{ width: '100%' }} />
                                </Form.Control>
                            </Flex>
                        </Form.Field>

                        <Form.Field className="FormField" name='threshold_similarity' >
                            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                                <Form.Label className='FormLabel'>Threshold</Form.Label>
                                <Text>
                                    <Strong>
                                        {formData.threshold_similarity}
                                    </Strong>
                                </Text>
                                <Form.Message className='FormMessage' match="valueMissing">
                                    Insira um valor
                                </Form.Message>
                            </Flex>

                            <Flex align="center" style={{ justifyContent: 'space-between' }}>
                                <Form.Control disabled={loading} asChild>
                                    <input className="Input" onChange={updateField} value={formData.threshold_similarity} min="0" max="0.9" step="0.1" type='range' name='threshold_similarity' required style={{ width: '100%' }} />
                                </Form.Control>
                            </Flex>
                        </Form.Field>

                        <Form.Submit asChild>
                            <Button size="2" type="submit" loading={loading} >
                                Salvar
                                <ArrowRightIcon width={20} height={20} />
                            </Button>
                        </Form.Submit>
                    </Form.Root>
                </Flex>
            </Box>
        </Container>
    );
}

export { DomainsEdit };