import React, { useEffect, useCallback, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { Flex, Heading, Select, Separator, Switch } from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';

import { accountsDomainAllRequest } from '../../../api/accounts_domains';
import { profileShowDomainRequest } from '../../../api/profiles';

function IntegrationFormWhatsApp(props) {
    const { formData, setFormData, currentType } = props;
    const { user } = useAuth();
    const [domains, setDomains] = useState([]);
    const [profiles, setProfiles] = useState([]);

    const fetchDomain = useCallback(async () => {
        accountsDomainAllRequest(user.id, 1, 999).then((response) => {
            let domains = []
            response.data.data.forEach((attr) => {
                domains.push({ name: attr.domain.name });
            });

            setDomains(domains);
        }).catch((error) => {
            console.log(error);
        });
    }, [user.id]);

    const fetchProfiles = useCallback(async (domainName) => {
        profileShowDomainRequest(domainName, 1, 999).then((response) => {
            let profiles = []
            response.data.data.forEach((attr) => {
                profiles.push({ title: attr.title });
            });

            setProfiles(profiles);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (currentType && currentType === 'whatsapp') {
            fetchDomain();

            if (JSON.parse(formData?.configurations).domain_name) {
                fetchProfiles(JSON.parse(formData?.configurations).domain_name);
            }
        }

    }, [currentType, fetchDomain, fetchProfiles, formData?.configurations]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                [name]: value
            })
        }));
    }

    const handleTriggerChange = (event) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                triggers: JSON.parse(prev.configurations).triggers.map((trigger, index) => {
                    if (trigger.type === name) {
                        return {
                            ...trigger,
                            trigger: value
                        }
                    } else {
                        return trigger
                    }
                })
            })
        }));
    }

    const handleDomainChange = (value) => {
        if (formData?.configurations.domain_name !== value) {
            fetchProfiles(value);
        }

        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                creator_identifier: user.name,
                domain_name: value
            })
        }));
    }

    const handleProfileChange = (value) => {
        setFormData((prev) => ({
            ...prev,
            configurations: JSON.stringify({
                ...JSON.parse(prev.configurations),
                profile_title: value
            })
        }));
    }

    return (<>
        <Form.Field className="FormField" name="channel_id" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Número do Canal</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o ID do canal
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="text" name="channel_id" value={JSON.parse(formData?.configurations).channel_id} onChange={handleChange} required />
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="token" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Token</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o Token
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="text" name="token" value={JSON.parse(formData?.configurations).token} onChange={handleChange} required />
            </Form.Control>
        </Form.Field>

        <Separator size="4" mt='6' />

        <Heading size="2" my='2'>Configurações de Gatilhos</Heading>

        <Form.Field className="FormField" name="start" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Gatilho de Início</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o Gatilho de Início
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="text" name="start" value={JSON.parse(formData?.configurations).triggers[0].trigger} onChange={handleTriggerChange} required />
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="stop" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Gatilho de Parada</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o Gatilho de Parada
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="text" name="stop" value={JSON.parse(formData?.configurations).triggers[1].trigger} onChange={handleTriggerChange} required />
            </Form.Control>
        </Form.Field>

        <Separator size="4" mt='6' />

        <Heading size="2" my='2'>Configurações de Perfil</Heading>

        <Form.Field className="FormField" name="domain_id" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Domínio</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o Domínio
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <Select.Root value={JSON.parse(formData?.configurations).domain_name} defaultValue={JSON.parse(formData?.configurations).domain_name} onValueChange={handleDomainChange}>
                    <Select.Trigger placeholder="Selecione um domínio" disabled={domains.length === 0} />
                    <Select.Content>
                        <Select.Group>
                            <Select.Label>Selecione o domínio</Select.Label>
                            {domains.map((domain) => (
                                <Select.Item key={domain.name} value={domain.name}>{domain.name}</Select.Item>
                            ))}
                        </Select.Group>
                    </Select.Content>
                </Select.Root>
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="profile_title" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Perfil</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o Perfil
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <Select.Root value={JSON.parse(formData?.configurations).profile_title} onValueChange={handleProfileChange}>
                    <Select.Trigger placeholder="Selecione um perfil" disabled={profiles.length === 0} />
                    <Select.Content>
                        <Select.Group>
                            <Select.Label>Selecione o perfil</Select.Label>
                            {profiles.map((profile) => (
                                <Select.Item key={profile.title} value={profile.title}>{profile.title}</Select.Item>
                            ))}
                        </Select.Group>
                    </Select.Content>
                </Select.Root>
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="needs_moderation" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Moderação</Form.Label>
            </Flex>

            <Form.Control asChild>
                <Switch defaultChecked={formData.needs_moderation} onCheckedChange={(checked) => setFormData({ ...formData, needs_moderation: checked })} />
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="self_archiving" >
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Auto-arquivamento</Form.Label>
            </Flex>

            <Form.Control asChild>
                <Switch defaultChecked={formData.self_archiving} onCheckedChange={(checked) => setFormData({ ...formData, self_archiving: checked })} />
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="time_in_blacklist">
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Tempo de bloqueio(horas)</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o tempo em minutos
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="number" name="time_in_blacklist" value={formData.time_in_blacklist} onChange={(event) => setFormData({ ...formData, time_in_blacklist: event.target.value })} required />
            </Form.Control>
        </Form.Field>

        <Form.Field className="FormField" name="waiting_time_to_response">
            <Flex align="baseline" style={{ justifyContent: 'space-between' }}>
                <Form.Label className='FormLabel'>Tempo de resposta(minutos)</Form.Label>

                <Form.Message className='FormMessage' match="valueMissing">
                    Insira o tempo em minutos
                </Form.Message>
            </Flex>

            <Form.Control asChild>
                <input className="Input" type="number" name="waiting_time_to_response" value={formData.waiting_time_to_response} onChange={(event) => setFormData({ ...formData, waiting_time_to_response: event.target.value })} required />
            </Form.Control>
        </Form.Field>
    </>);
}

export { IntegrationFormWhatsApp }