import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex } from '@radix-ui/themes';
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';

function Pagination(props) {
    const navigate = useNavigate();

    const { meta, requestPerPage, requestPerPageArgs } = props;
    const neighbors = 2;

    const totalPages = () => {
        const totalPages = []
        for (let i = Math.max(1, meta.page - neighbors); i <= Math.min(meta.total_pages, meta.page + neighbors); i++) {
            totalPages.push(i);
        }

        return totalPages;
    }

    const requestClickPage = (e) => {
        e.preventDefault();

        const nextPage = parseInt(e.target.innerText)
        navigate(`?page=${nextPage}`);

        if (requestPerPageArgs) {
            requestPerPage(e, nextPage, requestPerPageArgs);
            return;
        } else {
            requestPerPage(e, nextPage);
        }
    };

    const requestNextPage = (e) => {
        e.preventDefault();
        const nextPage = (meta.page + 1 > meta.total_pages) ? 1 : meta.page + 1;

        navigate(`?page=${nextPage}`);

        if (requestPerPageArgs) {
            requestPerPage(e, nextPage, requestPerPageArgs);
            return;
        } else {
            requestPerPage(e, nextPage);
        }
    }

    const requestPreviousPage = (e) => {
        e.preventDefault();
        const nextPage = ((meta.page - 1) < 1) ? meta.total_pages : meta.page - 1;

        navigate(`?page=${nextPage}`);

        if (requestPerPageArgs) {
            requestPerPage(e, nextPage, requestPerPageArgs);
            return;
        } else {
            requestPerPage(e, nextPage);
        }
    }

    const firstAlias = () => {
        return meta.page > (neighbors + 1) ? true : false;
    }

    const lastAlias = () => {
        return meta.page < meta.total_pages - neighbors ? true : false;
    }

    return (
        <Box>
            <Flex gap='2' mt='4'>
                {totalPages().length > 1 && (
                    <>
                        <Button size="1" onClick={requestPreviousPage}>
                            <ArrowLeftIcon width={20} height={20} />
                        </Button>

                        {firstAlias() && (
                            <>
                                <Button size="1" onClick={requestClickPage}>
                                    1
                                </Button>
                                <Button size="1" disabled={true}>
                                    ...
                                </Button>
                            </>
                        )}

                        {totalPages().map((page) => (
                            <Button key={page} size="1" disabled={meta.page === page} onClick={requestClickPage} >
                                {page}
                            </Button>
                        ))}

                        {lastAlias() && (
                            <>
                                <Button size="1" disabled={true}>
                                    ...
                                </Button>
                                <Button size="1" onClick={requestClickPage}>
                                    {meta.total_pages}
                                </Button>
                            </>
                        )}

                        <Button size="1" onClick={requestNextPage}>
                            <ArrowRightIcon width={20} height={20} />
                        </Button>
                    </>
                )}
            </Flex>
        </Box>
    );
}

export { Pagination }