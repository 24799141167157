const displayOnlyLaptopsAndDesktops = (flex) => {
    if (flex) {
        return {
            initial: 'none',
            lg: 'flex',
            xl: 'flex'
        }
    } else {
        return {
            initial: 'none',
            lg: 'block',
            xl: 'block'
        }
    }
}

const displayOnlyMobiles = (flex) => {
    if (flex) {
        return {
            initial: 'flex',
            lg: 'none',
            xl: 'none'
        }
    } else {
        return {
            initial: 'block',
            lg: 'none',
            xl: 'none'
        }
    }
}

export { displayOnlyLaptopsAndDesktops, displayOnlyMobiles }