import { Container, Flex, Button, Tabs, Box, Badge, Heading, Text } from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CreatorInputsTable } from './index/creator_inputs_table';
import { LinkExtractionsTable } from './index/link_extractions_table';
import { useAuth } from '../../hooks/auth';
import { useEffect, useState, useCallback } from 'react';
import { linkExtractionPendingRequest } from '../../api/link_extractions';
import { displayOnlyLaptopsAndDesktops, displayOnlyMobiles } from '../../contexts/responsive_tools';

function CreatorInputsIndex() {
    const location = useLocation();
    const navigate = useNavigate();
    const [state] = useState(location.state ? location.state : { tabs: 'creator_inputs' });
    const { user } = useAuth();
    const [pendingCount, setPendingCount] = useState(0);

    const badgePending = () => {
        return (
            pendingCount > 0 && <Badge color="orange" ml='2'>{pendingCount}</Badge>
        );
    }

    const fetchLinkExtractionPendingCount = useCallback(() => {
        linkExtractionPendingRequest(user.id).then((response) => {
            setPendingCount(response.data.pending_items);
        }).catch((error) => {
            console.log(error);
        });
    }, [user]);

    useEffect(() => {
        fetchLinkExtractionPendingCount();
    }, [fetchLinkExtractionPendingCount]);

    const changeTab = (e) => {
        const tabName = e.target.getAttribute('name');
        const newState = { ...state, tabs: tabName };
        const newLocation = { ...location, state: newState, pathname: '/memorias', search: '', hash: '' };
        navigate(newLocation, { replace: true });
    }

    return (
        <Container size='4' maxWidth='100%' p='2'>
            <Flex gap='2' my='6'>
                <Heading>Mémorias</Heading>
                <Button size="2" ml='auto' asChild>
                    <Link to='/memorias/nova'>
                        <Box display={displayOnlyLaptopsAndDesktops()}>
                            Inserir nova memória
                        </Box>

                        <Flex display={displayOnlyMobiles(true)}>
                            <PlusIcon />
                        </Flex>
                    </Link>
                </Button>
            </Flex>

            <Tabs.Root defaultValue={state.tabs}>
                <Tabs.List>
                    <Tabs.Trigger name='creator_inputs' value="creator_inputs" onClick={changeTab} asChild>
                        <Text name='creator_inputs'>Mémorias</Text>
                    </Tabs.Trigger>
                    <Tabs.Trigger name='link_extractions' value="link_extractions" onClick={changeTab}>
                        <Text name='link_extractions'>Arquivos ou links {badgePending()}</Text>
                    </Tabs.Trigger>
                </Tabs.List>

                <Box pt="3">
                    <Tabs.Content value="creator_inputs">
                        <CreatorInputsTable />
                    </Tabs.Content>

                    <Tabs.Content value="link_extractions">
                        <LinkExtractionsTable />
                    </Tabs.Content>
                </Box>
            </Tabs.Root>
        </Container>
    );
}

export { CreatorInputsIndex };