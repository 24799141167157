
import { Container, Heading } from '@radix-ui/themes';
import { CategorizeForm } from './form_types/categorize_form';

function CreatorInputsCategorize() {
    return (
        <Container size="3" align='center' p='2'>
            <Heading>Classificação da mémoria</Heading>
            <CategorizeForm />
        </Container>
    );
}

export { CreatorInputsCategorize };