import { Button, Flex } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { Logo } from './logo';

function PublicHeader() {
    const navigate = useNavigate();
    const goTo = (url) => {
        navigate(url)
    }

    return (
        <Flex gap="4" py='2' align='center' style={{justifyContent: 'space-around'}}>
            <Logo />

            <Flex align='center' gap="2">
                <Button size='2' onClick={ () => { goTo('/login') }}>Entrar</Button>
                <Button size='2' variant="outline" onClick={ () => { goTo('/cadastro') }}>Cadastrar</Button>
            </Flex>
        </Flex>
    );
}

export { PublicHeader };