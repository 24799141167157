import React, { useEffect, useRef } from 'react';
import * as Toast from '@radix-ui/react-toast';

function ToastInfo(props) {
    const { children, toastOpen, setToastOpen, message, type = 'ToastError' } = props;
    const eventDateRef = useRef(new Date());
    const timerRef = useRef(0);

    useEffect(() => {
        const timer = timerRef.current;
        return () => clearTimeout(timer);
    }, []);

    return (
        <Toast.Provider swipeDirection="right">
            {children}
            <Toast.Root className={`ToastRoot ${type}`} open={toastOpen} onOpenChange={setToastOpen}>
                <Toast.Title className="ToastTitle">Informação para você!</Toast.Title>
                <Toast.Description asChild>
                    <time className="ToastDescription" dateTime={eventDateRef.current.toISOString()}>
                        {message}
                    </time>
                </Toast.Description>

            </Toast.Root>
            <Toast.Viewport className="ToastViewport" />
        </Toast.Provider>
    );
}

export { ToastInfo }